import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { updateAccountThunk } from '../../store/slices/auth/authThunks';

const CreateAccount = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const [birthday, setBirthday] = useState<Date | null>();
  const [user, setUser] = useState({
    zip: '',
    phone: '',
    gender: '',
    birthday: '',
  });

  const genderOptions = [
    { value: 'M', label: 'Male' },
    { value: 'F', label: 'Female' },
    { value: 'O', label: 'Other' },
  ];

  const { loading } = useSelector((state: RootState) => state.auth);

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setUser({ ...user, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const objToSumbit = {
      ...user,
      birthday: birthday ? birthday.toLocaleDateString('en-CA') : null,
    };

    const filteredFields = Object.fromEntries(
      Object.entries(objToSumbit).filter(
        ([_, value]) => value !== '' && value !== undefined && value !== null
      )
    );
    try {
      const returend = await dispatch(updateAccountThunk(filteredFields));
      if (returend.payload.success) {
        navigate('/');
      } else {
        navigate('/login');
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  return (
    <div className="flex flex-col bg-white h-screen">
      <div className="flex-shrink-0 p-4 sm:p-6 lg:p-8">
        <header className="mb-2 sm:mb-4">
          <Link to="/">
            <img
              className="h-10 sm:h-12 md:h-14 w-auto"
              src="/assets/411.svg"
              alt="411 logo"
            />
          </Link>
        </header>
        <div className="flex flex-wrap items-center justify-center pt-2 sm:space-x-4">
          <div className="bg-gray-200 px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Sign up
          </div>
          <span className="text-gray-500">→</span>
          <div className="bg-gray-200 px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Connect email
          </div>
          <span className="text-gray-500">→</span>
          <div className=" bg-[#BFB9FE] font-semibold  px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Create account
          </div>
        </div>
      </div>
      <div className="text-center mb-8 px-4">
        <h1 className="text-xl sm:text-2xl md:text-3xl text-[#91987B]">
          "An organized email is an organized mind."
        </h1>
        <div className="flex justify-end sm:w-full md:w-2/3 lg:w-2/3 mt-2">
          <p className="text-[#91987B] text-right ">-Socrates</p>
        </div>
      </div>
      <form
        className="flex flex-col items-center space-y-4 w-full max-w-md mx-auto"
        onSubmit={handleSubmit}
      >
        <div className="w-5/6  flex flex-col">
          <label
            htmlFor="birthday"
            className="block text-sm font-medium text-[#2C2C2C] mb-1"
          >
            Birthday
          </label>
          <DatePicker
            selected={birthday}
            onChange={(date) => setBirthday(date)}
            dateFormat="MM/dd/yyyy"
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={100}
            placeholderText="MM/DD/YYYY"
            className="p-2 border border-gray-300 rounded w-full focus:outline-none focus:ring-1 focus:ring-[#91987B] text-gray-500 mb-2"
            wrapperClassName="w-full"
          />
          <label
            htmlFor="gender"
            className="block text-sm font-medium text-[#2C2C2C] mb-1"
          >
            Gender
          </label>
          {/* <select
            id="gender"
            className="mb-2 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-[#91987B]"
            name="gender"
            onChange={handleChange}
            defaultValue=""
          >
            <option value="" disabled>
              Select gender
            </option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </select> */}
          <select
            id="gender"
            className="mb-2 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-[#91987B]"
            name="gender"
            onChange={handleChange}
            defaultValue=""
          >
            <option value="" disabled>
              Select
            </option>
            {genderOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

          <label
            htmlFor="zip"
            className="block text-sm font-medium text-[#2C2C2C] mb-1"
          >
            Zip
          </label>
          <input
            type="text"
            id="zip"
            className="mb-2 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-[#91987B]"
            name="zip"
            onChange={handleChange}
          />

          <label
            htmlFor="phone"
            className="block text-sm font-medium text-[#2C2C2C] mb-1"
          >
            Phone
          </label>
          <input
            type="text"
            id="phone"
            className="mb-2 p-2 border border-gray-300 rounded-md w-full focus:outline-none focus:ring-2 focus:ring-[#91987B]"
            name="phone"
            onChange={handleChange}
          />
        </div>
        <button
          type="submit"
          className="w-4/6 sm:w-4/6 mb-2 py-2 px-4 border border-transparent text-base font-medium rounded-md text-white bg-[#91987B] hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
        >
          GO
        </button>
        {loading && <p className="text-[#2C2C2C]">Loading...</p>}
        {/* {updateErrors && (
          <p className="text-red-500">{updateErrors.join(', ')}</p>
        )} */}
      </form>

      <div className="mt-auto px-4 py-6 bottom-0 ">
        <p className="text-center text-xs sm:text-sm md:text-base opacity-65 max-w-2xl mx-auto text-[#2C2C2C]">
          By creating an account, you agree to our Terms of Service and Privacy
          Policy.
        </p>
      </div>
    </div>
  );
};

export default CreateAccount;
