export function findCollectionAndUserByIdShared(
  sharedWithMe: any,
  collectionId: string | undefined
) {
  function searchInCollections(collections: any, user: any): any {
    for (const collection of collections) {
      if (collection.id === collectionId) {
        return { collection, user };
      }
      if (collection.children && collection.children.length > 0) {
        const found = searchInCollections(collection.children, user);
        if (found) return found;
      }
    }
    return null;
  }

  function searchInCategory(category: any) {
    for (const item of category) {
      if (item.collections) {
        const found = searchInCollections(item.collections, item.user);
        if (found) return found;
      }
    }
    return null;
  }

  const categories = ['collections', 'cabinets', 'products'];

  for (const categoryName of categories) {
    const found = searchInCategory(sharedWithMe[categoryName]);
    if (found) return found;
  }

  return null;
}
