import React, { useEffect, useRef, useState } from 'react';
import UsersListModal from '../modals/UsersListModal';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import ShareModal from '../modals/ShareModal';

interface ContextMenuProps {
  isOpen: boolean;
  onClose: () => void;
  handleAddClick: () => void;
}

const ContextMenu: React.FC<ContextMenuProps> = ({
  isOpen,
  onClose,
  handleAddClick,
}) => {
  const cabinet = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'CABINET'
    )
  );
  const menuRef = useRef<HTMLDivElement>(null);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showUsersListModal, setShowUsersListModal] = useState(false);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      menuRef.current &&
      !menuRef.current.contains(event.target as Node) &&
      !showShareModal &&
      !showUsersListModal
    ) {
      onClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, showShareModal, showUsersListModal]);

  if (!isOpen) return null;

  const handleShareCabinet = () => {
    setShowShareModal(true);
  };

  const handleNewFolder = () => {
    handleAddClick();
    onClose();
  };

  const handleCabinetSharedWith = () => {
    setShowUsersListModal(true);
  };

  return (
    <>
      <div
        ref={menuRef}
        className="absolute top-7 mt-2 w-56 bg-white border rounded shadow-lg z-10 "
      >
        <div className="p-2">
          <button
            onClick={handleShareCabinet}
            className="flex items-center p-2 w-full hover:bg-gray-100"
          >
            <img
              src="/assets/side-bar-context-menu/cabinetShareIcon.svg"
              alt="share"
              className="w-5 h-5 mr-2"
            />
            Share my Franklie
          </button>
          <button
            onClick={handleNewFolder}
            className="flex items-center p-2 w-full hover:bg-gray-100"
          >
            <img
              src="/assets/side-bar-context-menu/new-list.svg"
              alt="folder"
              className="w-5 h-5 mr-2"
            />
            New list
          </button>
          <button
            onClick={handleCabinetSharedWith}
            className="flex items-center p-2 w-full hover:bg-gray-100"
          >
            <img
              src="/assets/cabinet-item-context-icons/sharedWith.svg"
              alt="shared"
              className="w-5 h-5 mr-2"
            />
            Franklie shared with
          </button>
        </div>
      </div>
      {showUsersListModal && (
        <UsersListModal
          isVisible={showUsersListModal}
          onClose={() => setShowUsersListModal(false)}
          collection={cabinet}
        />
      )}
      {showShareModal && (
        <ShareModal
          isVisible={showShareModal}
          onClose={() => setShowShareModal(false)}
          collection={cabinet}
        />
      )}
    </>
  );
};

export default ContextMenu;
