import React from 'react';
import LoginForm from '../../components/auth/LoginForm';
import { Link } from 'react-router-dom';

const LoginPage: React.FC = () => {
  return (
    <div className="flex flex-col h-screen w-screen bg-white p-4 sm:p-6 md:p-8">
      <header className="flex-shrink-0 sticky top-0 mb-2 sm:mb-4">
        <Link to="/">
          <img
            className="h-14 w-auto cursor-pointer"
            src="/assets/411.svg"
            alt="411 logo"
          />
        </Link>
      </header>
      <main className="flex-col flex h-full justify-center items-center">
        <div className="max-w-screen-lg text-center">
          <h1 className="text-4xl sm:text-xl md:text-4xl lg:text-5xl font-medium text-[#91987B] mb-2 sm:mb-3">
            We take the headache out of shopping
          </h1>
          <h2 className="sm:text-sm md:text-base text-[#91987B] mb-4 sm:mb-6">
            We track your personal shopping history for you. Never wonder what
            you bought, booked or spent ever again.
          </h2>
          <LoginForm />
        </div>
      </main>
    </div>
  );
};

export default LoginPage;
