import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { VIEW_TYPES } from '../../../assets/constants';
interface CurrentViewState {
  view: any | null;
  viewType:
    | keyof typeof VIEW_TYPES
    | keyof typeof VIEW_TYPES.SHAREDWITHME
    | null;
}

const initialState: CurrentViewState = {
  view: null,
  viewType: null,
};

const currentViewSlice = createSlice({
  name: 'currentView',
  initialState,
  reducers: {
    setCurrentView(
      state,
      action: PayloadAction<{
        view: any;
        viewType:
          | keyof typeof VIEW_TYPES
          | keyof typeof VIEW_TYPES.SHAREDWITHME;
      }>
    ) {
      state.view = action.payload.view;
      state.viewType = action.payload.viewType;
    },
    clearCurrentView(state) {
      state.view = null;
      state.viewType = null;
    },
  },
});

export const { setCurrentView, clearCurrentView } = currentViewSlice.actions;

export default currentViewSlice.reducer;
