import { Link } from 'react-router-dom';

const MyStarredSection = () => {
  return (
    <Link to="/my-starred">
      <div className="relative flex items-center justify-between mb-2 cursor-pointer py-2 hover:bg-gray-200 rounded w-full text-left ml-1">
        <div className="flex items-center">
          <img src="/assets/base/starred.svg" className="w-8 h-8" />
          <span className="font-medium pl-1 text-lg mb-2">My starred</span>
        </div>
      </div>
    </Link>
  );
};

export default MyStarredSection;
