import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  moveProductAction,
  removeProductFromCollection,
  setCollectionProducts,
  updatePurchasedProductNoteAction,
} from './productActions';
import { GET_COLLECTION_PRODUCTS } from '../../../graphql/queries/products/getCollectionProducts';
import client from '../../../utils/api';
import { MOVE_PURCHASED_PRODUCT_BETWEEN_COLLECTIONS } from '../../../graphql/mutations/products/movePurchasedProductBetweenCollections';
import { DELETE_PURCHASED_PRODUCT } from '../../../graphql/mutations/products/deletePurchasedProduct';
import { DUPLICATE_PRODUCT_MUTATION } from '../../../graphql/mutations/products/duplicatePurchasedProduct';
import { UPDATE_PRODUCT_IS_STARRED } from '../../../graphql/mutations/products/startProduct';
import { UPDATE_PURCHASED_PRODUCT_NOTE } from '../../../graphql/mutations/products/updateNote';

export const fetchCollectionProducts = createAsyncThunk(
  'products/fetchCollectionProducts',
  async (collectionId: string, { dispatch }) => {
    try {
      const response = await client.query({
        query: GET_COLLECTION_PRODUCTS,
        variables: { id: collectionId },
        fetchPolicy: 'network-only',
      });

      const products: any[] =
        response.data.collection.purchasedProducts.edges.map((edge: any) => ({
          id: edge.node.id,
          privacyStatus: edge?.node?.privacyStatus,
          createdAt: edge?.node?.createdAt,
          isStarred: edge?.node?.isStarred,
          name: edge?.node?.product?.name,
          image: edge?.node.product?.image,
          description: edge?.node?.product?.description,
          currency: edge?.node?.product?.currency,
          url: edge?.node?.product?.url,
          price: edge?.node?.product?.price,
          brand: edge?.node?.product?.brand?.name,
        }));

      dispatch(
        setCollectionProducts({
          collectionName: response.data.collection.id,
          products,
        })
      );
      return products;
    } catch (error) {
      console.error(error, 'error while fetching collection products');
    }
  }
);

export const moveProductBetweenCollectionsThunk = createAsyncThunk(
  'products/moveProductBetweenCollections',
  async (
    {
      oldCollectionId,
      newCollectionId,
      productId,
    }: {
      oldCollectionId: string;
      newCollectionId: string;
      productId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    // Optimistically update the state
    dispatch(moveProductAction(oldCollectionId, newCollectionId, productId));

    try {
      const response = await client.mutate({
        mutation: MOVE_PURCHASED_PRODUCT_BETWEEN_COLLECTIONS,
        variables: {
          input: {
            oldCollectionId,
            newCollectionId,
            purchasedProductId: productId,
          },
        },
      });

      const result = response.data.movePurchasedProductBetweenCollections;

      if (!result.success) {
        // Rollback the optimistic update if the mutation failed
        return rejectWithValue(result.errors);
      }

      return { success: true };
    } catch (error: any) {
      // Rollback the optimistic update if there's a network error
      return rejectWithValue(error.message);
    }
  }
);

export const deleteProductThunk = createAsyncThunk(
  'products/deleteProduct',
  async (
    {
      productId,
      collectionId,
    }: {
      productId: string;
      collectionId: string;
    },
    { dispatch }
  ) => {
    try {
      const response = await client.mutate({
        mutation: DELETE_PURCHASED_PRODUCT,
        variables: { id: productId },
      });

      if (response.data.deletePurchasedProduct.success) {
        dispatch(removeProductFromCollection({ collectionId, productId }));
        return { success: true };
      } else {
        throw new Error(response.data.deletePurchasedProduct.errors[0]);
      }
    } catch (error) {
      console.error('Error deleting product:', error);
      throw error;
    }
  }
);

export const duplicateProductThunk = createAsyncThunk(
  'products/duplicateProduct',
  async (
    {
      productId,
      sourceCollectionId,
      targetCollectionId,
    }: {
      productId: string;
      sourceCollectionId: string;
      targetCollectionId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: DUPLICATE_PRODUCT_MUTATION,
        variables: {
          input: {
            purchasedProductId: productId,
            collectionId: targetCollectionId,
          },
        },
      });

      if (data.duplicatePurchasedProduct.success) {
        await dispatch(fetchCollectionProducts(sourceCollectionId));
        await dispatch(fetchCollectionProducts(targetCollectionId));

        return { success: true };
      } else {
        return rejectWithValue(data.duplicatePurchasedProduct.errors);
      }
    } catch (error) {
      return rejectWithValue('An error occurred while duplicating the product');
    }
  }
);

export const starProductThunk = createAsyncThunk(
  'products/starProduct',
  async (
    {
      productId,
      targetCollectionId,
      isStarred,
      soruceCollectionId,
    }: {
      productId: string;
      targetCollectionId: string;
      isStarred: boolean;
      soruceCollectionId: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_PRODUCT_IS_STARRED,
        variables: {
          id: productId,
          isStarred,
        },
      });

      if (data.updatePurchasedProductIsStarred.success) {
        await dispatch(fetchCollectionProducts(targetCollectionId));
        await dispatch(fetchCollectionProducts(soruceCollectionId));

        return { success: true };
      } else {
        return rejectWithValue(data.updatePurchasedProductIsStarred.errors);
      }
    } catch (error) {
      return rejectWithValue('An error occurred while duplicating the product');
    }
  }
);

export const updatePurchasedProductNoteThunk = createAsyncThunk(
  'purchasedProducts/updateNote',
  async (
    { id, notes }: { id: string; notes: string },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_PURCHASED_PRODUCT_NOTE,
        variables: { id, input: { notes } },
      });

      if (data.updatePurchasedProductNote.success) {
        dispatch(updatePurchasedProductNoteAction({ id, notes }));
        return { id, notes };
      } else {
        return rejectWithValue(data.updatePurchasedProductNote.errors);
      }
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);
