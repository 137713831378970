import { gql } from '@apollo/client';

export const DELETE_PURCHASED_PRODUCT = gql`
  mutation deletePurchasedProduct($id: ID!) {
    deletePurchasedProduct(id: $id) {
      success
      errors
    }
  }
`;
