import React from 'react';

const PrivacyAndSharing: React.FC = () => {
  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-24 py-6 max-w-7xl mx-auto">
      <h1 className="text-xl sm:text-2xl font-[500] mb-6">Sharing & Privacy</h1>
      <div className="px-12">
        <p className="mb-8 font-bold">You're always in charge.</p>

        <section className="mb-6">
          <h2 className="text-sm font-semibold mb-2">Inbox</h2>
          <p className="text-xs">
            Your Inbox is only visible to you. This is where your email receipts
            and confirmations sit until you file them to a specific list.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-sm font-semibold mb-2">Your Franklie</h2>
          <p className="text-xs pb-1">
            If you share your Franklie with friends, they can see your lists
            that are set to Unhidden (eye icon). If you'd like to share your
            Franklie but keep some lists private, simply set those lists to
            Hidden (lock icon). If you wish to un-share your Franklie with
            friends, simply click the 3 dots to the right of My Franklie and
            click “Franklie shared with.” You can then remove the friends for
            whom you’d like to remove access.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-sm font-semibold mb-2">Your lists</h2>
          <p className="text-xs pb-1">
            You can choose to share individual lists with friends by clicking
            the 3 dot menu to the right of the list name and choosing “Share
            with friends.” When a list is shared with friends, those friends can
            see the contents of that list. If you wish to un-share the list,
            simply click “Shared with” and choose to remove the friends for whom
            you’d like to remove access.
          </p>
        </section>

        {/* <section className="mb-6">
          <h2 className="text-sm font-semibold mb-2">
            Your items within lists
          </h2>
          <p className="text-xs">
            Your items within private lists are only visible to you. Your items
            within lists that are public are visible to those you have shared
            that list with, unless you have marked that item as Private. To mark
            an item Private, simply click the eye icon in the bottom right
            corner. The icon will switch to a padlock, which means that item is
            hidden from external viewers. To make an item Public, simply click
            the padlock and the icon will revert back to the eye icon.
          </p>
        </section> */}

        <section className="mb-6">
          <h2 className="text-sm font-semibold mb-2">
            Deleted items, and lists
          </h2>
          <p className="text-xs">
            If you delete items from your Inbox, that item will be deleted from
            your account forever. If you delete filed items or lists, those
            items and those lists including the items they contain will be
            deleted from your account forever.
          </p>
        </section>

        <section>
          <h2 className="text-sm font-semibold mb-2">Archive</h2>
          <p className="text-xs">
            Your Archive is only visible to you. This is where you can move
            items or lists that you no longer use but do not want to delete. If
            you move an item from a list to the Archive, that item no longer
            exists in that list and is viewable only by you in the Archive.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyAndSharing;
