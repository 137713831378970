// store/slice/collections/collectionsThunk.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addCollection,
  renameCollection,
  setCollections,
  setError,
  setLoading,
  deleteCollection,
  duplicateCollection,
  updateCollectionParent,
  updateCollection,
} from './collectionActions';
import client from '../../../utils/api';
import { GET_COLLECTIONS } from '../../../graphql/queries/collections/getUserCollections';
import { CREATE_COLLECTION } from '../../../graphql/mutations/collections/createCollection';
import { RENAME_COLLECTION } from '../../../graphql/mutations/collections/renameCollection';
import DELETE_COLLECTION from '../../../graphql/mutations/collections/deleteCollection';
import { UPDATE_COLLECTION_PARENT } from '../../../graphql/mutations/collections/updateCollectionParent';
import { DUPLICATE_COLLECTION } from '../../../graphql/mutations/collections/duplicateCollection';
import { UPDATE_COLLECTION_PRIVACY_STATUS } from '../../../graphql/mutations/collections/updateCollectionPrivacyStatus';

export const fetchCollections = createAsyncThunk(
  'collections/fetchCollections',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.query({
        query: GET_COLLECTIONS,
        fetchPolicy: 'network-only',
      });

      const collectionsData = response.data.userCollections.edges.map(
        (edge: any) => edge.node
      );

      thunkAPI.dispatch(setCollections(collectionsData));
      thunkAPI.dispatch(setError(null));
      thunkAPI.dispatch(setLoading(false));

      return collectionsData;
    } catch (error: any) {
      thunkAPI.dispatch(setError(error.message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createCollection = createAsyncThunk(
  'collections/addCollection',
  async ({ name, parent }: { name: string; parent: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.mutate({
        mutation: CREATE_COLLECTION,
        variables: { name, parent },
      });
      if (response.data.createCollection.success) {
        const newCollection: any = response.data.createCollection.collection;
        thunkAPI.dispatch(addCollection(newCollection));
        thunkAPI.dispatch(setError(null));
        thunkAPI.dispatch(setLoading(false));
        return newCollection;
      }
    } catch (error: any) {
      thunkAPI.dispatch(setError(error.message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const renameCollectionThunk = createAsyncThunk(
  'collections/renameCollection',
  async ({ id, name }: { id: string; name: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.mutate({
        mutation: RENAME_COLLECTION,
        variables: { id, input: { name } },
      });
      const updatedCollection = response.data.updateCollection.collection;
      thunkAPI.dispatch(renameCollection(updatedCollection));
      thunkAPI.dispatch(setError(null));
      thunkAPI.dispatch(setLoading(false));
      return updatedCollection;
    } catch (error: any) {
      thunkAPI.dispatch(setError(error.message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteCollectionThunk = createAsyncThunk(
  'collections/deleteCollection',
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.mutate({
        mutation: DELETE_COLLECTION,
        variables: { id },
      });
      thunkAPI.dispatch(deleteCollection(id));
      thunkAPI.dispatch(setError(null));
      thunkAPI.dispatch(setLoading(false));

      return response.data.deleteCollection.collection.id;
    } catch (error: any) {
      thunkAPI.dispatch(setError(error.message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateCollectionParentThunk = createAsyncThunk(
  'collections/updateCollectionParent',
  async ({ id, parentId }: { id: string; parentId: string }, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.mutate({
        mutation: UPDATE_COLLECTION_PARENT,
        variables: { id, input: { parent: parentId } },
      });
      const updatedCollection = response.data.updateCollection.collection;
      thunkAPI.dispatch(updateCollectionParent(updatedCollection));
      thunkAPI.dispatch(setError(null));
      thunkAPI.dispatch(setLoading(false));
      return updatedCollection;
    } catch (error: any) {
      thunkAPI.dispatch(setError(error.message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const duplicateCollectionThunk = createAsyncThunk(
  'collections/duplicateCollection',
  async (id: string, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.mutate({
        mutation: DUPLICATE_COLLECTION,
        variables: { id },
      });
      thunkAPI.dispatch(duplicateCollection(id));
      thunkAPI.dispatch(setError(null));
      thunkAPI.dispatch(setLoading(false));

      return response.data.duplicateCollection.collection;
    } catch (error: any) {
      thunkAPI.dispatch(setError(error.message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const updateCollectionPrivacyStatusThunk = createAsyncThunk(
  'collections/updateCollectionPrivacyStatus',
  async (
    { id, privacyStatus }: { id: string; privacyStatus: string },
    thunkAPI
  ) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.mutate({
        mutation: UPDATE_COLLECTION_PRIVACY_STATUS,
        variables: { id, privacyStatus },
      });
      const updatedCollection =
        response.data.updateCollectionPrivacyStatus.collection;
      thunkAPI.dispatch(updateCollection(updatedCollection));
      thunkAPI.dispatch(setError(null));
      thunkAPI.dispatch(setLoading(false));
      return updatedCollection;
    } catch (error: any) {
      thunkAPI.dispatch(setError(error.message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
