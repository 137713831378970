import { gql } from '@apollo/client';

export const DUPLICATE_PRODUCT_MUTATION = gql`
  mutation DuplicatePurchasedProduct($input: DuplicatePurchasedProductInput!) {
    duplicatePurchasedProduct(input: $input) {
      success
      errors
    }
  }
`;
