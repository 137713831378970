/**
 * Generates the Google authentication URL with the appropriate parameters.
 * @param {string} scopes - The scopes to be included in the authentication request.
 * @param {boolean} isGmailConnect - Whether the authentication is for Gmail connection.
 * @returns {string} The generated Google authentication URL.
 */
const getGoogleAuthUrl = (scopes: string, isGmailConnect: boolean = false) => {
  const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';

  // Use environment-specific redirect URI
  const redirectUri = `${import.meta.env.VITE_APP_URL}/auth/google/callback`;

  const params = new URLSearchParams({
    client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
    redirect_uri: redirectUri,
    response_type: 'code',
    scope: scopes,
    access_type: 'offline',
    prompt: 'consent',
    state: isGmailConnect ? 'gmail_connect' : 'auth', // Add state to differentiate between flows
  });

  return `${googleAuthUrl}?${params.toString()}`;
};

/**
 * Opens a new authentication window.
 * @param {string} url - The URL to be opened in the new window.
 * @returns {void}
 */
const openAuthWindow = (url: string) => {
  const isMobile =
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );

  if (isMobile) {
    window.location.href = url;
  } else {
    const width = 500;
    const height = 600;
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    window.open(
      url,
      'Google Sign-In',
      `width=${width},height=${height},left=${left},top=${top}`
    );
  }
};

/**
 * Handles the Google authentication process.
 * @returns {void}
 */
export const handleGoogleAuth = () => {
  const scopes = 'openid email profile';
  const url = getGoogleAuthUrl(scopes);
  openAuthWindow(url);
};

/**
 * Handles the Gmail connection process.
 * @returns {void}
 */
export const handleGoogleGmailConnect = () => {
  const scopes =
    'openid email profile https://www.googleapis.com/auth/gmail.readonly';
  const url = getGoogleAuthUrl(scopes, true);
  openAuthWindow(url);
};
