import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { SCREEENS_URL } from '../../../assets/constants';

interface MenuItem {
  title: string;
  path: string;
}

const MenuItem: React.FC<{ item: MenuItem }> = ({ item }) => (
  <Link
    to={`/${item.path}`}
    className="text-left w-1/3 px-4 py-1 border border-[#DADADA] rounded flex justify-between items-center"
  >
    {item.title}
  </Link>
);

const SharePreferences: React.FC = () => {
  const [showPrices, setShowPrices] = useState<'Y' | 'N'>('N');

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto">
      <h1 className="text-xl sm:text-2xl font-[500] mb-6">Share Preferences</h1>

      <nav className="space-y-2">
        <MenuItem
          item={{
            title: 'My info',
            path: SCREEENS_URL.SETTINGS.MY_ACCOUNT.MY_INFO,
          }}
        />
        <MenuItem
          item={{
            title: 'Share preferences',
            path: SCREEENS_URL.SETTINGS.MY_ACCOUNT.SHARED_PREF,
          }}
        />
        <div className="font-[500] sm:pl-8 flex items-center">
          Show prices when I share my data?
          <button
            className={`border py-1 px-2 rounded ml-4 ${showPrices === 'Y' ? 'bg-gray-500 text-white' : ''}`}
            onClick={() => setShowPrices('Y')}
          >
            Y
          </button>
          <button
            className={`border py-1 px-2 rounded ml-2 ${showPrices === 'N' ? 'bg-gray-500 text-white' : ''}`}
            onClick={() => setShowPrices('N')}
          >
            N
          </button>
        </div>

        <MenuItem
          item={{
            path: SCREEENS_URL.SETTINGS.MY_ACCOUNT.DELETE_ACCOUNT,
            title: 'Delete my account',
          }}
        />
      </nav>
    </div>
  );
};

export default SharePreferences;
