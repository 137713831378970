import { useMutation } from '@apollo/client';
import React, { useState } from 'react';
import { DELETE_ACCOUNT_MUTATION } from '../../../graphql/mutations/auth/deleteAccount';
import client from '../../../utils/api';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { resetState } from '../../../store/rootActions';
import { persistor } from '../../../store';
import ConfirmationModal from '../../modals/ConfirmationModal';
import { REVOKE_TOKEN_MUTATION } from '../../../graphql/mutations/auth/revokeTokenMutation';

const DeleteAccount: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [deleteAccount, { loading }] = useMutation(DELETE_ACCOUNT_MUTATION, {
    client,
    onCompleted: async (data) => {
      if (data.deleteAccount.success) {
        try {
          await client.mutate({
            mutation: REVOKE_TOKEN_MUTATION
          });
          dispatch(resetState());
          await persistor.purge();
          navigate('/login');
        } catch (error) {
          console.error('Error during logout:', error);
          setErrorMessage('An error occurred during logout. Please try again.');
        }
      } else {
        setErrorMessage(data.deleteAccount.errors.password[0].message);
      }
    },
    onError: (error) => {
      console.error(error);
      setErrorMessage(
        'Error deleting account. Please check your password and try again.'
      );
    },
  });

  const handleDelete = async () => {
    deleteAccount();
  };

  const handleDisapproveDelete = () => {
    navigate(-1);
  };

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-24 py-6 max-w-7xl mx-auto">
      <h1 className="text-xl sm:text-xl md:text-2xl font- mb-6">
        Delete my account
      </h1>
      <p className="text-sm sm:text-base mb-8">You're leaving?!</p>

      {errorMessage && (
        <p className="text-red-500 text-sm mb-4">{errorMessage}</p>
      )}

      <div className="flex flex-col sm:flex-row justify-center items-center sm:space-x-4">
        <button
          className="border border-gray-200 text-gray-400 py-2 px-4 mb-4 sm:mb-0 sm:w-52 w-full rounded"
          onClick={() => setShowConfirmationModal(true)}
        >
          {loading ? 'Deleting...' : "Yep, I'm out."}
        </button>
        <button
          className="border border-gray-500 text-gray-500 py-2 px-4 sm:w-52 w-full rounded"
          onClick={handleDisapproveDelete}
        >
          Jk I'm staying.
        </button>
        {showConfirmationModal && (
          <ConfirmationModal
            message="Are you sure you want to delete your account ?"
            onConfirm={handleDelete}
            onClose={() => setShowConfirmationModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default DeleteAccount;
