import { gql } from '@apollo/client';
import client from '../../../utils/api';
import store from '../../../store';

// Define the mutation
export const GOOGLE_GMAIL_CONNECT_MUTATION = gql`
  mutation googleGmailConnect($code: String!) {
    googleGmailConnect(code: $code) {
      success
      user {
        id
        username
        lastName
        firstName
        email
        emailPermissionGranted
      }
      token
      grantedScopes
      emailPermissionGranted
    }
  }
`;

// Define types for the mutation variables and response
export interface GoogleGmailConnectMutationVariables {
  code: string;
}

export interface GoogleGmailConnectMutationResponse {
  googleGmailConnect: {
    success: boolean;
    user: {
      id: string;
      username: string;
      lastName: string;
      firstName: string;
      email: string;
      emailPermissionGranted: boolean;
    };
    token: string;
    grantedScopes: string[];
    emailPermissionGranted: boolean;
  };
}

// Function to execute the mutation
export const executeGoogleGmailConnectMutation = async (code: string) => {
  try {
    // Get current auth token from Redux store
    const currentToken = store.getState().auth.token;

    const response = await client.mutate<
      GoogleGmailConnectMutationResponse,
      GoogleGmailConnectMutationVariables
    >({
      mutation: GOOGLE_GMAIL_CONNECT_MUTATION,
      variables: { code },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
      context: {
        headers: {
          'Content-Type': 'application/json',
          Authorization: currentToken ? `JWT ${currentToken}` : '',
        },
      },
    });

    if (response.errors) {
      console.error('GraphQL errors:', response.errors);
      throw new Error(response.errors[0].message);
    }

    if (!response.data) {
      throw new Error('No data received from mutation');
    }

    return response.data.googleGmailConnect;
  } catch (error) {
    console.error('Error executing Gmail connect mutation:', error);
    throw error;
  }
};
