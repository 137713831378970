export const SIDEBAR_ITEM_TYPE = 'SIDEBAR_ITEM';

export const VIEW_TYPES = {
  CABINET: 'CABINET',
  STARRED: 'STARRED',
  ARCHIVE: 'ARCHIVE',
  INBOX: 'INBOX',
  SETTINGS: 'SETTINGS',
  CUSTOM: 'CUSTOM', // refers to manually created collections
  SHAREDWITHME: {
    CABINETS: 'CABINETS',
    LISTS: 'LISTS',
    PRODUCTS: 'PRODUCTS',
  },
} as const;

export type ViewTypes = (typeof VIEW_TYPES)[keyof typeof VIEW_TYPES];
export type SharedWithMeViewTypes =
  (typeof VIEW_TYPES.SHAREDWITHME)[keyof typeof VIEW_TYPES.SHAREDWITHME];

export const SCREEENS_URL = {
  CABINET: 'my-franklie',
  STARRED: 'my-starred',
  ARCHIVE: 'archive',
  INBOX: 'inbox',
  CUSTOM: 'lists', // refers to manually created collections
  SHAREDWITHME: {
    CABINETS: 'shared-franklies',
    LISTS: 'shared-lists',
    PRODUCTS: 'shared-products',
  },
  SHARED_LIST: 'shared-list',
  PRODUCT: 'product',
  SETTINGS: {
    INDEX: 'settings',
    MY_ACCOUNT: {
      INDEX: 'my-account',
      SHARED_PREF: 'shared-pref',
      DELETE_ACCOUNT: 'delete-account',
      MY_INFO: 'my-info',
    },
    SHARING_PRIVACY: 'sharing&privacy',
    TERMS: 'terms&conditions',
    PRIVACY_POLICY: 'privacy-policy',
    CONTACT_US: 'contact-us',
  },
} as const;

export type ScreenTypes = (typeof SCREEENS_URL)[keyof typeof SCREEENS_URL];
