// helpers/getInitials.ts

import { RootState } from '../store';
import { useSelector } from 'react-redux';

// Function to get initials from user state in the Redux store
export const getInitials = (): string => {
  // Access the Redux store state
  const user = useSelector((state: RootState) => state.auth?.user);

  // Extract first and last names
  const { firstName, lastName } = user || {};

  // Get the first letter of first and last names
  const firstInitial = firstName?.charAt(0).toUpperCase();
  const lastInitial = lastName?.charAt(0).toUpperCase();

  // Combine initials
  const initials = `${firstInitial}${lastInitial}`;

  // Return initials
  return initials;
};
