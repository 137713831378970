import { gql } from '@apollo/client';

export const CREATE_COLLECTION = gql`
  mutation createCollection($name: String!, $parent: ID!) {
    createCollection(input: { name: $name, parent: $parent }) {
      success
      errors
      collection {
        isShared
        type
        name
        id
        privacyStatus
        parent {
          id
        }
      }
    }
  }
`;
