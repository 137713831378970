import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import ConfirmationModal from '../modals/ConfirmationModal';
import {
  moveProductBetweenCollectionsThunk,
  deleteProductThunk,
  duplicateProductThunk,
  starProductThunk,
} from '../../store/slices/products/productThunks';

interface ContextMenuProps {
  isOpen: boolean;
  onClose: () => void;
  product: any;
  currentCollectionId: string;
}

const ProductContextMenu: React.FC<ContextMenuProps> = ({
  isOpen,
  onClose,
  product,
  currentCollectionId,
}) => {
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationHandler, setConfirmationHandler] = useState<() => void>(
    () => {}
  );
  const [isMoveToOpen, setIsMoveToOpen] = useState(false);
  const [isDuplicateToOpen, setIsDuplicateToOpen] = useState(false);

  const collections = useSelector(
    (state: RootState) =>
      state.collections.collections.find(
        (collection: any) => collection.type === 'CABINET'
      )?.children || []
  );
  const archiveCollection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'ARCHIVE'
    )
  );
  const starredCollection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'STARRED'
    )
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        // onClose();
        setIsMoveToOpen(false);
        setIsDuplicateToOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleMoveTo = () => {
    setIsMoveToOpen((prev) => !prev);
    setIsDuplicateToOpen(false);
  };

  const handleDuplicateTo = () => {
    setIsDuplicateToOpen((prev) => !prev);
    setIsMoveToOpen(false);
  };

  const handleSelectList = (
    targetCollection: any,
    action: 'move' | 'duplicate'
  ) => {
    setConfirmationMessage(
      `Are you sure you want to ${action} "${product.name}" to "${targetCollection.name}"?`
    );
    setConfirmationHandler(() =>
      action === 'move'
        ? () => handleConfirmMove(targetCollection)
        : () => handleConfirmDuplicate(targetCollection.id)
    );
    setShowConfirmationModal(true);
  };

  const handleConfirmMove = async (targetCollection: any) => {
    await dispatch(
      moveProductBetweenCollectionsThunk({
        productId: product.id,
        oldCollectionId: currentCollectionId,
        newCollectionId: targetCollection.id,
      }) as any
    );

    setShowConfirmationModal(false);
    onClose();
  };

  const handleConfirmDuplicate = async (targetCollectionId: string) => {
    dispatch(
      duplicateProductThunk({
        sourceCollectionId: currentCollectionId,
        targetCollectionId,
        productId: product.id,
      } as any) as any
    );

    setShowConfirmationModal(false);
    onClose();
  };

  const handleArchive = () => {
    setConfirmationMessage(
      `Are you sure you want to archive "${product.name}"?`
    );
    setConfirmationHandler(() => handleConfirmArchive);
    setShowConfirmationModal(true);
  };

  const handleConfirmArchive = async () => {
    if (archiveCollection) {
      await dispatch(
        moveProductBetweenCollectionsThunk({
          productId: product.id,
          oldCollectionId: currentCollectionId,
          newCollectionId: archiveCollection.id,
        }) as any
      );
    }
    setShowConfirmationModal(false);
    onClose();
  };

  const handleStarred = () => {
    // setConfirmationMessage(`Are you sure you want to star "${product.name}"?`);
    // setConfirmationHandler(() => handleConfirmStarred);
    // setShowConfirmationModal(true);
    handleConfirmStarred();
  };

  const handleConfirmStarred = async () => {
    await dispatch(
      starProductThunk({
        productId: product.id,
        targetCollectionId: starredCollection.id,
        isStarred: true,
        soruceCollectionId: currentCollectionId,
      }) as any
    );
    setShowConfirmationModal(false);
    onClose();
  };

  const handleUnstarItem = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await dispatch(
      starProductThunk({
        productId: product.id,
        targetCollectionId: starredCollection.id,
        isStarred: false,
        soruceCollectionId: currentCollectionId,
      }) as any
    );
    onClose();
  };

  const handleDeleteItem = () => {
    setConfirmationMessage(
      `Are you sure you want to delete "${product.name}"?`
    );
    setConfirmationHandler(() => handleConfirmDelete);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    await dispatch(
      deleteProductThunk({
        productId: product.id,
        collectionId: currentCollectionId,
      }) as any
    );

    setShowConfirmationModal(false);
    onClose();
  };

  return (
    <div
      ref={menuRef}
      className={`absolute z-10 top-full w-48 bg-white rounded-md shadow-lg ${isOpen ? 'block' : 'hidden'}`}
    >
      <ul className="py-1">
        <li
          onClick={handleMoveTo}
          className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
        >
          <img
            src="/assets/product-context-menu/addToList.svg"
            className="w-4 h-4 mr-2"
            alt="Move to"
          />
          Move to...
        </li>
        {isMoveToOpen &&
          collections.map((list: any) => (
            <li
              key={list.id}
              onClick={() => handleSelectList(list, 'move')}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
            >
              <img
                src={`/assets/archive-context-menu/folder.svg`}
                className="w-4 h-4 mx-3"
                alt={list.name}
              />
              {list.name}
            </li>
          ))}
        <li
          onClick={handleDuplicateTo}
          className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
        >
          <img
            src="/assets/product-context-menu/duplicate.svg"
            className="w-4 h-4 mr-2"
            alt="Duplicate to"
          />
          Duplicate to...
        </li>
        {isDuplicateToOpen &&
          collections.map((list: any) => (
            <li
              key={list.id}
              onClick={() => handleSelectList(list, 'duplicate')}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
            >
              <img
                src={`/assets/archive-context-menu/folder.svg`}
                className="w-4 h-4 mx-3"
                alt={list.name}
              />
              {list.name}
            </li>
          ))}
        {(isMoveToOpen || isDuplicateToOpen) && (
          <li
            onClick={() =>
              handleSelectList(
                { id: 'new', name: 'New list' },
                isMoveToOpen ? 'move' : 'duplicate'
              )
            }
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
          >
            {/* <img
              src="/assets/archive-context-menu/new-list.svg"
              className="w-4 h-4 mr-2"
              alt="New list"
            />
            New list */}
          </li>
        )}
        <li
          onClick={!product.isStarred ? handleStarred : handleUnstarItem}
          className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
        >
          <img
            src={
              !product.isStarred
                ? '/assets/star.svg'
                : '/assets/base/starred.svg'
            }
            alt={!product.isStarred ? 'unstar' : 'star'}
            className="w-5 h-5 mr-2"
          />
          {!product.isStarred ? 'Star Item' : 'Unstar item'}
        </li>
        <li
          onClick={handleArchive}
          className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
        >
          <img
            src="/assets/archive-context-menu/archive.svg"
            className="w-4 h-4 mr-2"
            alt="Archive"
          />
          Archive
        </li>
        <li
          onClick={handleDeleteItem}
          className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
        >
          <img
            src="/assets/archive-context-menu/delete.svg"
            className="w-4 h-4 mr-2"
            alt="Delete item"
          />
          Delete item
        </li>
      </ul>
      {showConfirmationModal && (
        <ConfirmationModal
          message={confirmationMessage}
          onConfirm={confirmationHandler}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default ProductContextMenu;
