import { gql } from '@apollo/client';

export const RENAME_COLLECTION = gql`
  mutation renameCollection($id: ID!, $input: UpdateCollectionInput!) {
    updateCollection(id: $id, input: $input) {
      collection {
        isShared
        type
        name
        id
        privacyStatus
        parent {
          id
        }
        children {
          id
          pk
          name
          isShared
          name
          id
          type
          isPermanent
          privacyStatus
          parent {
            id
          }
          children {
            id
            pk
            name
            isShared
            name
            id
            type
            isPermanent
            privacyStatus
            parent {
              id
            }
            children {
              id
              pk
              name
              isShared
              name
              id
              type
              isPermanent
              privacyStatus
              parent {
                id
              }
            }
          }
        }
      }
    }
  }
`;
