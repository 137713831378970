import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { useSelector } from 'react-redux';
import useDebounce from '../../hooks/useDebounce';
import client from '../../utils/api';
import { SEARCH_USERS } from '../../graphql/queries/users/searchUsers';
import { RootState } from '../../store';
import { SHARE_PURCHASED_PRODUCT } from '../../graphql/mutations/products/createSharedProduct';

interface ShareProductModalProps {
  isVisible: boolean;
  onClose: () => void;
  product: any;
}

const ShareProductModal: React.FC<ShareProductModalProps> = (
  { isVisible, onClose, product },
  _ref
) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState('');
  const [selectedUser, setSelectedUser] = useState<{
    id: string;
    firstName: string;
    lastName: string;
  } | null>(null);
  const [users, setUsers] = useState<
    { id: string; firstName: string; lastName: string; username: string }[]
  >([]);

  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const currentUser = useSelector((state: RootState) => state.auth.user);

  const [searchUsers, { loading, error }] = useLazyQuery(SEARCH_USERS, {
    client,
    onCompleted: (data) => {
      const filteredUsers = data.users.edges
        .map((edge: any) => edge.node)
        .filter((user: { id: string }) => user.id !== currentUser?.id);
      setUsers(filteredUsers);
    },
  });

  const [createSharedProduct] = useMutation(SHARE_PURCHASED_PRODUCT, {
    client,
    onCompleted: (data) => {
      if (data.createSharedPurchasedProduct.success) {
        setSuccessMessage(
          `"${product.product.name}" shared with ${selectedUser?.firstName} ${selectedUser?.lastName} successfully!`
        );
        setErrorMessage(null);
        setTimeout(() => {
          onClose();
        }, 2000);
      }
    },
    onError: (error) => {
      setErrorMessage(error.message);
      setSuccessMessage(null);
    },
  });

  const debouncedQuery = useDebounce(query, 500);

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
      setTimeout(() => modalRef.current?.querySelector('input')?.focus(), 0);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      setSelectedUser(null);
      setSuccessMessage(null);
      setErrorMessage(null);
      setUsers([]);
      setQuery('');
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible]);

  useEffect(() => {
    if (debouncedQuery) {
      searchUsers({ variables: { search: debouncedQuery } });
    } else {
      setUsers([]);
    }
  }, [debouncedQuery, searchUsers]);

  const handleUserSelect = (user: {
    id: string;
    firstName: string;
    lastName: string;
  }) => {
    setSelectedUser(user);
    setSuccessMessage(null);
    setErrorMessage(null);
  };

  const handleShare = () => {
    if (selectedUser) {
      createSharedProduct({
        variables: {
          purchased_product: product.id,
          secondary_user: selectedUser.id,
        },
      });
    }
  };

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-[#D7DBCE] p-6 rounded-lg shadow-xl text-center w-full sm:w-2/3 lg:w-1/2 opacity-95"
      >
        <h2 className="text-xl mb-4 font-medium text-black">
          Share "{product.product.name}"
        </h2>
        <div className="mb-4 bg-white bg-opacity-70 p-2 rounded-md">
          <input
            type="text"
            placeholder="Enter the name or username of the person you'd like to share with"
            className="w-full bg-transparent text-black text-lg sm:text-lg placeholder:text-xs sm:placeholder:text-base focus:outline-none"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          />
        </div>
        {loading && (
          <div className="flex items-center justify-center mt-4">
            <svg
              className="animate-spin h-5 w-5 text-black"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              ></path>
            </svg>
            <p className="ml-2 text-black">Loading...</p>
          </div>
        )}
        {error && <p className="mt-4 text-red-500">Error: {error.message}</p>}
        {!loading && !error && users.length === 0 && debouncedQuery && (
          <p className="mt-4 text-black">No results found.</p>
        )}
        {users.length > 0 && (
          <ul className="mt-4 mb-4 max-h-32 overflow-y-auto">
            {users.map((user) => (
              <li
                key={user.id}
                className={`p-2 cursor-pointer text-black hover:bg-white hover:bg-opacity-50 ${
                  selectedUser?.id === user.id ? 'bg-white bg-opacity-50' : ''
                }`}
                onClick={() => handleUserSelect(user)}
              >
                {user.firstName} {user.lastName} ({user.username})
              </li>
            ))}
          </ul>
        )}
        {selectedUser && (
          <div className="mt-4 flex justify-end">
            <button
              onClick={handleShare}
              className="bg-white text-black px-4 py-2 rounded-full hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-150 ease-in-out"
            >
              Share
            </button>
          </div>
        )}
        {errorMessage && <div className="mt-4 text-black">{errorMessage}</div>}
        {successMessage && (
          <div className="mt-4 text-black">{successMessage}</div>
        )}
      </div>
    </div>,
    document.body
  );
};

export default ShareProductModal;
