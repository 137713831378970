import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { fetchCollectionProducts } from '../../store/slices/products/productThunks';
import InboxProductItem from './InboxProductItem';
import LoadingSpinner from '../loading';

const UnsortedMail: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);

  // Select the INBOX collection from the state
  const inboxCollection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection) => collection.type === 'INBOX'
    )
  );

  // Select products associated with the INBOX collection
  const products = useSelector(
    (state: RootState) => state.products[inboxCollection?.id]
  );

  useEffect(() => {
    const fetchProducts = async () => {
      if (inboxCollection) {
        setIsLoading(true);
        await dispatch(fetchCollectionProducts(inboxCollection.id));
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [inboxCollection, dispatch, inboxCollection.id]);

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto flex flex-col">
      <div className="flex items-center mb-4 sm:mb-6">
        <img
          src="/assets/unsortedMail.svg"
          className="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 py-1"
          alt="Unsorted Mail"
        />
        <h2 className="text-xl sm:text-2xl md:text-3xl pl-2">
          {inboxCollection?.name}
        </h2>
      </div>
      <p className="text-sm text-gray-500">
        This is your Inbox, where your email receipts will appear. After syncing
        your Gmail, we'll automatically import your purchases from the past 30
        days. Check back in about 15 minutes to see your receipts.
      </p>
      <p className="text-sm text-gray-500 mt-4">
        To sync Gmail, go to Menu {'>'} Settings {'>'} My Account {'>'} My Info.
        Or forward receipts to{' '}
        <a href="mailto:myreceipts@franklie.ai" className="text-black">
          myreceipts@franklie.ai
        </a>
      </p>
      <div className="flex justify-center px-4 sm:px-8 md:px-12 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-1 w-5/6 lg:grid-cols-2 gap-y-4 gap-x-8 sm:gap-x-10 lg:gap-x-16">
          {isLoading ? (
            <div className="col-span-full flex justify-center">
              <LoadingSpinner />
            </div>
          ) : products && products.length > 0 ? (
            products.map((product) => (
              <InboxProductItem key={product.id} product={product} />
            ))
          ) : (
            <div className="col-span-full text-center py-8 text-gray-500">
              No products in {inboxCollection.name}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UnsortedMail;
