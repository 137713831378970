import React, { useState } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import { starProductThunk } from '../../store/slices/products/productThunks';

interface StarredProductItemProps {
  product: {
    id: string;
    privacyStatus: string;
    createdAt: string;
    isStarred: boolean;
    name: string;
    image: string;
    description: string;
    currency: string;
    url: string;
    price: string;
    brand: string;
    isShared: boolean;
  };
}

const StarredProductItem: React.FC<StarredProductItemProps> = ({ product }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formattedDate = moment(product.createdAt).format('MMM Do, YYYY');
  const [isHovered, setIsHovered] = useState(false);

  const starredCollection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'STARRED'
    )
  );

  const handleStarredClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    handleUnstarItem(e);
  };

  const handleUnstarItem = async (e: React.MouseEvent) => {
    e.stopPropagation();
    await dispatch(
      starProductThunk({
        productId: product.id,
        targetCollectionId: starredCollection.id,
        isStarred: false,
        soruceCollectionId: starredCollection.id,
      }) as any
    );
  };

  const handleItemClick = () => {
    navigate(`/product/${product.id}`);
  };

  return (
    <div
      className="flex flex-col sm:flex-row border rounded-lg shadow-md p-4 m-2 w-11/12 sm:max-w-lg lg:max-w-xl relative cursor-pointer bg-white hover:shadow-lg transition-shadow duration-300"
      onClick={handleItemClick}
    >
      <img
        src={product.image || '/assets/411.svg'}
        alt={product.name}
        className="w-full h-52 sm:w-32 sm:h-40 mb-4 sm:mb-0 sm:mr-4 object-cover"
      />
      <div className="flex-1 flex flex-col">
        <div className="flex justify-between items-start mb-2">
          <h3 className="font-bold sm:text-lg mb-2">{product.name}</h3>
        </div>
        <p className="text-sm text-gray-600">
          {product.brand || 'Product Brand'}
        </p>
        <p className="text-gray-800 text-lg sm:text-base">
          ${product.price || 'Price'}
        </p>
        <p className="text-gray-500 text-xs">{formattedDate}</p>
        <div className="absolute bottom-2 right-2 w-12 h-12 flex items-center justify-center text-gray-500  rounded-full focus:outline-none transition-colors duration-200">
          <button
            onClick={handleStarredClick}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <img
              src={isHovered ? '/assets/star.svg' : '/assets/base/starred.svg'}
              alt={isHovered ? 'unstar' : 'star'}
              className="w-10 h-10 transition-opacity duration-300"
              title="Remove from my starred"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default StarredProductItem;
