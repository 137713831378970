import { useEffect, useCallback } from 'react';
import { useAuthCallback } from '../../hooks';

/**
 * GoogleAuthCallback component to handle Google authentication and Gmail connection.
 * @returns {React.FC}
 */
const GoogleAuthCallback = () => {
  const {
    loading,
    setLoading,
    authError,
    setAuthError,
    handleGoogleAuth,
    handleGmailConnect,
  } = useAuthCallback();

  // Create a stable callback that won't change between renders
  const processAuthCallback = useCallback(async () => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    const state = urlParams.get('state');
    const error = urlParams.get('error');

    // Handle errors from Google
    if (error) {
      const errorMessage =
        urlParams.get('error_description') || 'Authentication failed';
      setAuthError(errorMessage);
      return;
    }

    // Check if the code and state are present
    if (!code || !state) {
      setAuthError('Invalid authentication parameters. Please try again.');
      return;
    }

    setLoading(true);

    try {
      // Handle different authentication states
      switch (state) {
        case 'gmail_connect':
          await handleGmailConnect(code);
          break;
        case 'auth':
          await handleGoogleAuth(code);
          break;
        default:
          setAuthError('Invalid state parameter. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  }, []); // Empty dependency array since these functions are stable

  // Process the authentication callback once
  useEffect(() => {
    processAuthCallback();
  }, [processAuthCallback]);

  // Close the window after 3 seconds if there's an error
  useEffect(() => {
    if (authError) {
      const timer = setTimeout(() => window.close(), 3000);
      return () => clearTimeout(timer);
    }
  }, [authError]);

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <div className="bg-white p-4 rounded-lg shadow">
          <p className="text-gray-600">Authenticating...</p>
        </div>
      </div>
    );
  }

  if (authError) {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen p-4">
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 max-w-md w-full">
          <p className="text-red-600 mb-2">Error: {authError}</p>
          <p className="text-gray-600">
            This window will close automatically in a few seconds.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-center min-h-screen">
      <div className="bg-white p-4 rounded-lg shadow">
        <p className="text-gray-600">Completing authentication...</p>
      </div>
    </div>
  );
};

export default GoogleAuthCallback;
