import { gql } from '@apollo/client';

const DELETE_COLLECTION = gql`
  mutation DeleteCollection($id: ID!) {
    deleteCollection(id: $id) {
      success
      errors
      collection {
        id
        name
      }
    }
  }
`;

export default DELETE_COLLECTION;
