import { gql } from '@apollo/client';

export const UPDATE_PURCHASED_PRODUCT_PRIVACY_STATUS = gql`
  mutation UpdatePurchasedProductPrivacyStatus(
    $id: ID!
    $input: UpdatePurchasedProductPrivacyStatusInput!
  ) {
    updatePurchasedProductPrivacyStatus(id: $id, input: $input) {
      success
      errors
    }
  }
`;
