import React from 'react';
import { Link } from 'react-router-dom';
import SignUpForm from '../../components/auth/SignupForm';

const SignUp: React.FC = () => {
  return (
    <div className="flex flex-col bg-white h-screen ">
      <div className="flex-shrink-0 p-4 sm:p-6 lg:p-8">
        <header className="mb-2 sm:mb-4">
          <Link to="/">
            <img
              className="h-10 sm:h-12 md:h-14 w-auto"
              src="/assets/411.svg"
              alt="411 logo"
            />
          </Link>
        </header>
        <div className="flex flex-wrap items-center justify-center pt-2 sm:space-x-4">
          <div className="bg-[#BFB9FE] font-semibold px-2 sm:px-4 sm:py-2 rounded text-[#2C2C2C]">
            Sign up
          </div>
          <span className="text-gray-500">→</span>
          <div className="bg-gray-200 px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Connect email
          </div>
          <span className="text-gray-500">→</span>
          <div className="bg-gray-200 px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Create account
          </div>
        </div>
      </div>
      <div className="flex-grow flex flex-col items-center ">
        <h1 className="text-center text-[#91987B] text-lg sm:text-xl md:text-2xl lg:text-2xl mb-1">
          Sign up
        </h1>
        <div className="flex-grow w-full max-w-md  px-4">
          <SignUpForm />
        </div>
      </div>
    </div>
  );
};

export default SignUp;
