import { gql } from '@apollo/client';

export const REGISTER_MUTATION = gql`
  mutation RegisterAccount(
    $email: String!
    $username: String!
    $password1: String!
    $password2: String!
    $firstName: String!
    $lastName: String!
  ) {
    register(
      email: $email
      username: $username
      password1: $password1
      password2: $password2
      firstName: $firstName
      lastName: $lastName
    ) {
      errors
      success
      token
      user {
        firstName
        lastName
        id
        birthday
        zip
        email
        gender
        phone
        username
      }
    }
  }
`;
