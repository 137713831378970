import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import store from '../store';

/**
 * Error handling link for Apollo Client.
 * Logs GraphQL and network errors for debugging.
 */
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );

  if (networkError) {
    console.error(`[Network error]:`, networkError);
    // Log additional details about the network error
    if ('statusCode' in networkError) {
      console.error('Status code:', networkError.statusCode);
    }
    if ('result' in networkError) {
      console.error('Error result:', networkError.result);
    }
  }
});

/**
 * HTTP link for Apollo Client.
 * Configures the GraphQL endpoint and CORS credentials.
 */
const httpLink = createHttpLink({
  uri: import.meta.env.VITE_GRAPHQL_URI,
  credentials: 'include', // Include credentials for CORS
});

/**
 * Authentication link for Apollo Client.
 * Adds CSRF token and JWT token from Redux store to request headers.
 */
const authLink = setContext((_, { headers }) => {
  // Get CSRF token from cookies
  const getCsrfToken = () => {
    const name = 'csrftoken=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    for (let i = 0; i < cookieArray.length; i++) {
      let cookie = cookieArray[i].trim();
      if (cookie.indexOf(name) === 0) {
        return cookie.substring(name.length, cookie.length);
      }
    }
    return null;
  };

  const csrfToken = getCsrfToken();
  // Get JWT token from in-memory Redux store
  const token = store.getState().auth.token;

  return {
    headers: {
      ...headers,
      'X-CSRFToken': csrfToken || '',
      Authorization: token ? `JWT ${token}` : '',
      'Content-Type': 'application/json',
    },
  };
});

/**
 * Apollo Client instance.
 * Configures the client with error handling, auth, and caching settings.
 */
const client = new ApolloClient({
  link: from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  connectToDevTools: true,
});

export default client;
