import { gql } from '@apollo/client';

export const UPDATE_PURCHASED_PRODUCT_RECOMMENDATION = gql`
  mutation UpdatePurchasedProductRecommendation(
    $id: ID!
    $input: PurchasedProductInputRecommendation!
  ) {
    updatePurchasedProductRecommendation(id: $id, input: $input) {
      success
      errors
    }
  }
`;
