import { gql } from '@apollo/client';

export const GET_COLLECTION_PRODUCTS = gql`
  query getCollectionProducts($id: ID!) {
    collection(id: $id) {
      name
      id
      purchasedProducts {
        edges {
          node {
            id
            createdAt
            privacyStatus
            isStarred
            vendor
            orderReference
            product {
              name
              description
              currency
              image
              url
              price
              brand {
                name
              }
              color
              size
            }
          }
        }
      }
    }
  }
`;
