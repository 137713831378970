// graphql/queries/collections.ts
import { gql } from '@apollo/client';

export const GET_COLLECTIONS = gql`
  query userCollections {
    userCollections {
      edges {
        node {
          id
          name
          type
          isShared
          privacyStatus
          parent {
            id
          }
          children {
            id
            pk
            name
            isShared
            privacyStatus
            name
            id
            type
            isPermanent
            parent {
              id
            }
            children {
              id
              pk
              name
              isShared
              privacyStatus
              name
              id
              type
              isPermanent
              parent {
                id
              }
              children {
                id
                pk
                name
                isShared
                privacyStatus
                name
                id
                type
                isPermanent
                parent {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
