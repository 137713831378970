import { useState, useEffect, useCallback } from 'react';
import client from '../../../utils/api';
import { GET_PURCHASED_PRODUCT } from '../../../graphql/queries/products/getPurchasedProduct';
import { UPDATE_PURCHASED_PRODUCT_RECOMMENDATION } from '../../../graphql/mutations/products/updateRecommendation';
import { UPDATE_PURCHASED_PRODUCT_NOTE } from '../../../graphql/mutations/products/updateNote';
import { UPDATE_PURCHASED_PRODUCT_PRIVACY_STATUS } from '../../../graphql/mutations/products/updatePrivacyStatus';

export const useProduct = (productId: string) => {
  const [product, setProduct] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [notes, setNotes] = useState('');
  const [recommendation, setRecommendation] = useState<string>('');

  const fetchProduct = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await client.query({
        query: GET_PURCHASED_PRODUCT,
        variables: { id: productId },
        fetchPolicy: 'network-only',
      });
      setProduct(data.purchasedProduct);
      setNotes(data.purchasedProduct.notes || '');
      setRecommendation(data.purchasedProduct.recommendation || '');
      setError(null);
    } catch (err) {
      setError('Failed to fetch product data');
    }
    setLoading(false);
  }, [productId]);

  useEffect(() => {
    fetchProduct();
  }, [fetchProduct]);

  const handleRecommendation = async (value: string) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_PURCHASED_PRODUCT_RECOMMENDATION,
        variables: {
          id: product.id,
          input: { recommendation: value.toLowerCase() },
        },
      });
      if (data.updatePurchasedProductRecommendation.success) {
        setRecommendation(value);
        await fetchProduct();
      } else {
        setError('Failed to update recommendation');
      }
    } catch (err) {
      setError('Failed to update recommendation');
    }
  };

  const handleSubmitNotes = async () => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_PURCHASED_PRODUCT_NOTE,
        variables: {
          id: product.id,
          input: { notes },
        },
      });
      if (data.updatePurchasedProductNote.success) {
        await fetchProduct();
      } else {
        setError('Failed to update notes');
      }
    } catch (err) {
      setError('Failed to update notes');
    }
  };

  const handleUpdatePrivacyStatus = async (status: 'HIDDEN' | 'PRIVATE') => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_PURCHASED_PRODUCT_PRIVACY_STATUS,
        variables: {
          id: product.id,
          input: { privacyStatus: status },
        },
      });
      if (data.updatePurchasedProductPrivacyStatus.success) {
        await fetchProduct();
      } else {
        setError('Failed to update privacy status');
      }
    } catch (err) {
      setError('Failed to update privacy status');
    }
  };

  return {
    product,
    loading,
    error,
    notes,
    setNotes,
    recommendation,
    handleRecommendation,
    handleSubmitNotes,
    handleUpdatePrivacyStatus,
  };
};
