import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './slices/auth/authSlice';
import productsReducer from './slices/products/productReducer';
import collectionsReducer from './slices/collections';
import sharedWithMeReducer from './slices/sharedWithMe/sharedWithMeReducer';
import { RESET_STATE } from './rootActions';
import currentViewSlice from './slices/active-area/currentViewSlice';

const appReducer = combineReducers({
  auth: authReducer,
  collections: collectionsReducer,
  products: productsReducer,
  sharedWithMe: sharedWithMeReducer,
  currentView: currentViewSlice,
});

type AppState = ReturnType<typeof appReducer>;

const rootReducer = (state: AppState | undefined, action: any): AppState => {
  if (action.type === RESET_STATE) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
