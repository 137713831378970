// actions.ts
import { Action } from 'redux';

export const RESET_STATE = 'RESET_STATE';

export interface ResetStateAction extends Action<typeof RESET_STATE> {}

export const resetState = (): ResetStateAction => ({
  type: RESET_STATE,
});
