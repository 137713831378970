import { createAction } from '@reduxjs/toolkit';

export const setCollections = createAction<any>('collections/setCollections');
export const setLoading = createAction<boolean>('collections/setLoading');
export const setError = createAction<string | null>('collections/setError');

export const addCollection = createAction<any>('collections/addCollection');
export const renameCollection = createAction<any>(
  'collections/renameCollection'
);

export const deleteCollection = createAction<string>(
  'collections/deleteCollection'
);

export const updateCollectionParent = createAction<any>(
  'collections/updateCollectionParent'
);

export const updateCollection = createAction<any>('updateCollection');

export const duplicateCollection = createAction<any>(
  'collections/duplicateCollection'
);
