// src/App.tsx
import React, { useEffect } from 'react';
import AppRoutes from './routes';
import { useDispatch, useSelector } from 'react-redux';
import { refreshToken } from './store/slices/auth/authThunks';
import { AppDispatch } from './store';
import LoadingSpinner from './components/loading';

const App: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const token = useSelector((state: any) => state.auth.token);
  const loading = useSelector((state: any) => state.auth.loading);

  useEffect(() => {
    // Only try to refresh if we have a refresh token cookie but no token in Redux
    const isGoogleCallback = window.location.pathname.includes(
      '/auth/google/callback'
    );

    // Don't refresh if we're in the Google callback flow
    if (!token && !isGoogleCallback) {
      dispatch(refreshToken());
    }
  }, [dispatch, token]);

  if (loading) {
    return <LoadingSpinner />;
  }

  return <AppRoutes />;
};

export default App;
