import { gql } from '@apollo/client';

export const SHARE_PURCHASED_PRODUCT = gql`
  mutation createSharedPurchasedProduct(
    $secondary_user: ID!
    $purchased_product: ID!
  ) {
    createSharedPurchasedProduct(
      input: {
        purchased_product: $purchased_product
        secondary_user: $secondary_user
      }
    ) {
      success
      errors
    }
  }
`;
