import { gql } from '@apollo/client';
import client from '../../../utils/api';

// Define the mutation
export const GOOGLE_AUTH_MUTATION = gql`
  mutation googleAuth($code: String!) {
    googleAuth(code: $code) {
      token
      user {
        id
        username
        lastName
        firstName
        email
        birthday
        zip
        gender
        phone
      }
    }
  }
`;

// Define types for the mutation variables and response
export interface GoogleAuthMutationVariables {
  code: string;
}

export interface GoogleAuthMutationResponse {
  googleAuth: {
    token: string;
    user: {
      id: string;
      username: string;
      lastName: string;
      firstName: string;
      email: string;
      birthday: string;
      zip: string;
      gender: string;
      phone: string;
    };
  };
}

export type User = GoogleAuthMutationResponse['googleAuth']['user'];

// Function to execute the mutation
export const executeGoogleAuthMutation = async (code: string) => {
  try {
    const response = await client.mutate<
      GoogleAuthMutationResponse,
      GoogleAuthMutationVariables
    >({
      mutation: GOOGLE_AUTH_MUTATION,
      variables: { code },
    });

    return response.data?.googleAuth;
  } catch (error) {
    console.error('Error executing Google Auth mutation:', error);
    throw error;
  }
};
