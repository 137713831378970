import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  deleteCollectionThunk,
  updateCollectionParentThunk,
} from '../../store/slices/collections';
import ConfirmationModal from '../modals/ConfirmationModal';
import { RootState } from '../../store';

interface CollectionItemProps {
  collection: any;
}

const CollectionItem: React.FC<CollectionItemProps> = ({ collection }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationHandler, setConfirmationHandler] = useState<() => void>(
    () => {}
  );
  const [dropdownVisible, setDropdownVisible] = useState(false);
  // const [isMoveToOpen, setIsMoveToOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const cabinetCollection = useSelector((state: RootState) =>
    state.collections.collections.find((col: any) => col.type === 'CABINET')
  );

  // const collections = cabinetCollection?.children || [];

  const handleDeleteClick = useCallback(() => {
    setConfirmationMessage(
      `Are you sure you want to delete "${collection.name}"?`
    );
    setConfirmationHandler(() => handleConfirmDelete);
    setShowConfirmationModal(true);
  }, [collection.name]);

  const handleConfirmDelete = useCallback(() => {
    dispatch(deleteCollectionThunk(collection.id) as any);
    setShowConfirmationModal(false);
  }, [dispatch, collection.id]);

  const toggleDropdown = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    setDropdownVisible((prev) => !prev);
  }, []);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node) &&
      buttonRef.current &&
      !buttonRef.current.contains(event.target as Node)
    ) {
      setDropdownVisible(false);
      // setIsMoveToOpen(false);
    }
  }, []);

  // const handleMoveTo = useCallback(() => {
  //   setIsMoveToOpen((prev) => !prev);
  // }, []);

  // const handleSelectList = useCallback(
  //   (targetCollection: any) => {
  //     setConfirmationMessage(
  //       `Are you sure you want to move "${truncateText(collection.name, 20)}" to "${truncateText(targetCollection.name, 20)}"?`
  //     );
  //     setConfirmationHandler(() => () => handleConfirmMove(targetCollection));
  //     setShowConfirmationModal(true);
  //   },
  //   [collection.name]
  // );

  // const handleConfirmMove = useCallback(
  //   (targetCollection: any) => {
  //     dispatch(
  //       updateCollectionParentThunk({
  //         id: collection.id,
  //         parentId: targetCollection.id,
  //       }) as any
  //     );
  //     setShowConfirmationModal(false);
  //     setDropdownVisible(false);
  //   },
  //   [dispatch, collection.id]
  // );

  const handleReturnToFranklie = useCallback(() => {
    dispatch(
      updateCollectionParentThunk({
        id: collection.id,
        parentId: cabinetCollection?.id,
      }) as any
    );
    setDropdownVisible(false);
  }, [dispatch, collection.id, cabinetCollection?.id]);

  const handleNavigate = useCallback(() => {
    navigate(`/lists/${collection.id}`);
  }, [navigate, collection.id]);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  const truncateText = (text: string, maxLength: number) => {
    return text.length > maxLength
      ? text.substring(0, maxLength) + '...'
      : text;
  };

  return (
    <div
      className="relative flex items-center justify-between p-3 border border-gray-200 rounded-lg bg-white shadow-sm hover:shadow-md cursor-pointer w-full"
      onClick={handleNavigate}
    >
      <div className="flex items-center overflow-hidden">
        <img
          src="/assets/base/folder.svg"
          className="mr-2 w-5 h-5 flex-shrink-0"
          alt="Folder"
        />
        <span className="text-gray-700 truncate" title={collection.name}>
          {truncateText(collection.name, 30)}
        </span>
      </div>
      <div className="relative" onClick={(e) => e.stopPropagation()}>
        <button
          ref={buttonRef}
          onClick={toggleDropdown}
          className="text-gray-500 hover:text-gray-700 ml-2 flex-shrink-0"
          title="Options"
        >
          ...
        </button>
        {dropdownVisible && (
          <div
            ref={dropdownRef}
            className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded-md shadow-lg z-10"
          >
            {/* <div
              onClick={handleMoveTo}
              className="text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center whitespace-nowrap cursor-pointer"
            >
              <img
                src="/assets/product-context-menu/addToList.svg"
                className="w-4 h-4 mr-2 flex-shrink-0"
                alt="Move to"
              />
              <p className="px-2 truncate">Move to...</p>
            </div>
            {isMoveToOpen &&
              collections.map((list: any) => (
                <div
                  key={list.id}
                  onClick={() => handleSelectList(list)}
                  className="text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center whitespace-nowrap cursor-pointer"
                >
                  <img
                    src="/assets/archive-context-menu/folder.svg"
                    className="w-4 h-4 mx-3 flex-shrink-0"
                    alt={list.name}
                  />
                  <p className="px-2 truncate" title={list.name}>
                    {truncateText(list.name, 20)}
                  </p>
                </div>
              ))} */}
            <div
              onClick={handleReturnToFranklie}
              className="text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center whitespace-nowrap cursor-pointer"
            >
              <img
                src="/assets/archive-collection-context/back-to-franklie.svg"
                className="w-4 h-4 mr-2 flex-shrink-0"
                alt="Return to Franklie"
              />
              <p className="px-2 truncate">Return to Franklie</p>
            </div>
            <div
              onClick={handleDeleteClick}
              className="text-left px-4 py-2 text-gray-700 hover:bg-gray-100 flex items-center whitespace-nowrap cursor-pointer"
            >
              <img
                src="/assets/archive-collection-context/delete-forever.svg"
                className="w-4 h-4 mr-2 flex-shrink-0"
                alt="Delete forever"
              />
              <p className="px-2 truncate">Delete forever</p>
            </div>
          </div>
        )}
        {showConfirmationModal && (
          <ConfirmationModal
            message={confirmationMessage}
            onConfirm={confirmationHandler}
            onClose={() => setShowConfirmationModal(false)}
          />
        )}
      </div>
    </div>
  );
};

export default CollectionItem;
