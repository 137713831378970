import { gql } from '@apollo/client';

export const SHARED_WITH_USERS = gql`
  query SharedWithUsers($id: ID!) {
    sharedWithUsers(id: $id) {
      edges {
        node {
          id
          username
          firstName
          lastName
        }
      }
    }
  }
`;
