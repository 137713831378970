import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import CabninetSection from './CabninetSection';
import ArchiveSection from './ArchiveSection';
import MyStarredSection from './MyStarredSection';
import SharedWithMeSection from './SharedWithMeSection';
import { useNavigate } from 'react-router';

interface SidebarProps {
  className?: string;
}

const Sidebar: React.FC<SidebarProps> = ({ className }) => {
  const navigate = useNavigate();
  const userCollections = useSelector(
    (state: RootState) => state.collections?.collections
  );

  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [isCabinetCollapsed, setIsCabinetCollapsed] = useState(false);
  const [isSharedWithMeCollapsed, setIsSharedWithMeCollapsed] = useState(false);

  const cabinet = userCollections?.find((item: any) => item.type === 'CABINET');
  const shared = userCollections?.find(
    (item: any) => item.type === 'SHARED' && item.name === 'Shared With Me'
  );

  const handleContextMenuOpen = () => {
    setContextMenuOpen(!contextMenuOpen);
  };

  const toggleCabinetCollapse = () => {
    setIsCabinetCollapsed(!isCabinetCollapsed);
  };

  const toggleCabinetNavigate = () => {
    navigate('/my-franklie');
  };

  const toggleSharedWithMeCollapse = () => {
    setIsSharedWithMeCollapsed(!isSharedWithMeCollapsed);
  };

  return (
    <aside
      className={`w-full h-full bg-gray-100 overflow-y-auto ${className} `}
    >
      <div className="p-4 space-y-4">
        <CabninetSection
          toggleCabinetCollapse={toggleCabinetCollapse}
          handleContextMenuOpen={handleContextMenuOpen}
          isCabinetCollapsed={isCabinetCollapsed}
          contextMenuOpen={contextMenuOpen}
          setContextMenuOpen={setContextMenuOpen}
          cabinet={cabinet}
          toggleCabinetNavigate={toggleCabinetNavigate}
        />
        <SharedWithMeSection
          toggleSharedWithMeCollapse={toggleSharedWithMeCollapse}
          isSharedWithMeCollapsed={isSharedWithMeCollapsed}
          shared={shared}
        />
        <MyStarredSection />
        <ArchiveSection />
      </div>
    </aside>
  );
};

export default Sidebar;
