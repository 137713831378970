import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import { fetchCollectionProducts } from '../../store/slices/products/productThunks';
import LoadingSpinner from '../loading';
import StarredProductItem from './StarredProductItem';

const MyStarred: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isLoading, setIsLoading] = useState(true);

  const myStarred = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection) => collection.type === 'STARRED'
    )
  );

  const products = useSelector(
    (state: RootState) => state.products[myStarred.id]
  );

  useEffect(() => {
    const fetchProducts = async () => {
      if (myStarred) {
        setIsLoading(true);
        await dispatch(fetchCollectionProducts(myStarred.id));
        setIsLoading(false);
      }
    };
    fetchProducts();
  }, [myStarred, dispatch]);

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto flex flex-col">
      <div className="flex items-center mb-4 sm:mb-6">
        <img
          src="/assets/base/starred.svg"
          className="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 mt-1"
          alt="Starred Items"
        />
        <h2 className="text-xl sm:text-2xl md:text-3xl pl-2">
          {myStarred.name}
        </h2>
      </div>
      <div className="flex justify-center px-4 sm:px-8 md:px-12 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-1 w-5/6 lg:grid-cols-2 gap-y-4 gap-x-8 sm:gap-x-10 lg:gap-x-16">
          {isLoading ? (
            <div className="col-span-full flex justify-center">
              <LoadingSpinner />
            </div>
          ) : products && products.length > 0 ? (
            products.map((product) => (
              <StarredProductItem key={product.id} product={product} />
            ))
          ) : (
            <div className="col-span-full text-center py-8">
              No starred items
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyStarred;
