import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../../store';
import DatePicker from 'react-datepicker';
import { updateAccountThunk } from '../../../store/slices/auth/authThunks';
import { setAuthState } from '../../../store/slices/auth';
import { handleGoogleGmailConnect } from '../../../utils/giveGooglePermission';
import { AuthUser } from '../../../types/authTypes';

interface FormState {
  username: string;
  firstName: string;
  lastName: string;
  birthday: Date | null;
  gender: string;
  zip: string;
  phone: string;
}

interface FormField {
  label: string;
  type: 'text' | 'tel' | 'select' | 'date';
  key: keyof FormState;
  placeholder?: string;
  options?: Array<{ value: string; label: string }>;
}

interface GmailConnectData {
  type: string;
  data: {
    user: AuthUser;
    token: string;
    success: boolean;
    emailPermissionGranted?: boolean;
    error?: string;
  };
}

interface GmailConnectButtonProps {
  email: string;
  isConnected: boolean;
  onConnect: (e: React.MouseEvent) => void;
}

const formFields: FormField[] = [
  { label: 'Username', type: 'text', key: 'username' },
  { label: 'First Name', type: 'text', key: 'firstName' },
  { label: 'Last Name', type: 'text', key: 'lastName' },
  { label: 'Birthday', type: 'date', key: 'birthday' },
  {
    label: 'Gender',
    type: 'select',
    key: 'gender',
    options: [
      { value: 'M', label: 'Male' },
      { value: 'F', label: 'Female' },
      { value: 'O', label: 'Other' },
    ],
  },
  { label: 'Zip', type: 'text', key: 'zip', placeholder: '94305' },
  { label: 'Phone', type: 'tel', key: 'phone', placeholder: '8909909900' },
];

const useMyInfoForm = (initialData: AuthUser | null) => {
  const [formState, setFormState] = useState<FormState>({
    username: initialData?.username || '',
    firstName: initialData?.firstName || '',
    lastName: initialData?.lastName || '',
    birthday: initialData?.birthday ? new Date(initialData.birthday) : null,
    gender: initialData?.gender || '',
    zip: initialData?.zip || '',
    phone: initialData?.phone || '',
  });

  const [submitMessage, setSubmitMessage] = useState('');
  const [submitError, setSubmitError] = useState('');
  const dispatch = useDispatch<AppDispatch>();

  const clearMessages = () => {
    setSubmitMessage('');
    setSubmitError('');
  };

  const handleInputChange = (
    key: keyof FormState,
    value: string | Date | null
  ) => {
    setFormState((prev) => ({ ...prev, [key]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    clearMessages();

    const updatedFields = {
      ...formState,
      birthday: formState.birthday
        ? formState.birthday.toISOString().split('T')[0]
        : null,
    };

    const filteredFields = Object.fromEntries(
      Object.entries(updatedFields).filter(
        ([_, value]) => value !== '' && value !== undefined && value !== null
      )
    );

    try {
      const response = await dispatch(updateAccountThunk(filteredFields));
      if (response.payload.success) {
        setSubmitMessage('Changes saved successfully!');
        setTimeout(clearMessages, 5000);
      } else {
        const errors = response.payload.errors;
        if (errors) {
          const errorMessages = Object.values(errors)
            .flat()
            .map((err: any) => err.message)
            .join('\n');
          setSubmitError(errorMessages);
        } else {
          setSubmitError('Failed to update account. Please try again.');
        }
      }
    } catch (error) {
      setSubmitError('An unexpected error occurred. Please try again.');
      console.error(error);
    }
  };

  return {
    formState,
    submitMessage,
    submitError,
    setSubmitMessage,
    setSubmitError,
    handleInputChange,
    handleSubmit,
    clearMessages,
    dispatch,
  };
};

const FormField: React.FC<{
  field: FormField;
  value: any;
  onChange: (key: keyof FormState, value: any) => void;
}> = ({ field, value, onChange }) => {
  if (field.type === 'date') {
    return (
      <DatePicker
        selected={value}
        onChange={(date) => onChange(field.key, date)}
        dateFormat="MM/dd/yyyy"
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={100}
        placeholderText="MM/DD/YYYY"
        className="px-3 py-2 border border-gray-300 rounded-md w-full"
        wrapperClassName="lg:w-full lg:pr-12 lg:pl-8"
      />
    );
  }

  if (field.type === 'select') {
    return (
      <select
        value={value}
        onChange={(e) => onChange(field.key, e.target.value)}
        className="px-3 py-2 border border-gray-300 rounded-md w-full sm:w-2/3"
      >
        <option value="" disabled>
          Select
        </option>
        {field.options?.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }

  return (
    <input
      type={field.type}
      value={value}
      onChange={(e) => onChange(field.key, e.target.value)}
      placeholder={field.placeholder}
      className="px-3 py-2 border border-gray-300 rounded-md w-full sm:w-2/3"
    />
  );
};

const GmailConnectButton: React.FC<GmailConnectButtonProps> = ({
  email,
  isConnected,
  onConnect,
}) => (
  <div className="mb-4 flex flex-col sm:flex-row items-center border rounded-lg p-4 bg-gray-50">
    <img
      src="/assets/gmail.png"
      alt="Gmail logo"
      className="mr-2 w-24 h-16 object-contain mb-2 sm:mb-0"
    />
    <div className="flex-grow pl-4">
      <div className="flex flex-col">
        {isConnected ? (
          <>
            <span className="text-sm font-medium text-gray-900">
              Gmail Connected
            </span>
            <span className="text-sm text-gray-500">{email}</span>
          </>
        ) : (
          <span className="text-sm text-gray-700">
            Connect Gmail so we can grab receipts for you
          </span>
        )}
      </div>
    </div>
    {!isConnected && (
      <button
        onClick={onConnect}
        className="mt-2 sm:mt-0 px-4 py-2 text-sm font-medium text-white bg-blue-600 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
      >
        Connect Gmail
      </button>
    )}
  </div>
);

const MyInfo: React.FC = () => {
  const user = useSelector((state: RootState) => state.auth?.user);
  const {
    formState,
    submitMessage,
    submitError,
    setSubmitMessage,
    setSubmitError,
    handleInputChange,
    handleSubmit,
    clearMessages,
    dispatch,
  } = useMyInfoForm(user);

  const handleGmailConnectResult = (key: string, value: string | null) => {
    if (!value) return;

    clearMessages();

    try {
      const data: GmailConnectData = JSON.parse(value);

      if (key === 'gmail_connect_success') {
        if (
          data.type === 'GOOGLE_GMAIL_CONNECT_SUCCESS' &&
          data.data?.success
        ) {
          const { user: updatedUser, token } = data.data;
          if (updatedUser && token) {
            dispatch(
              setAuthState({
                isAuthenticated: true,
                user: updatedUser,
                token,
              })
            );
            setSubmitMessage('Gmail connected successfully!');
          }
        }
      } else if (key === 'gmail_connect_error') {
        const errorMessage = data.data?.error || 'Failed to connect Gmail';
        setSubmitError(errorMessage);
      }
    } catch (error) {
      console.error(`Error handling ${key}:`, error);
      setSubmitError('Failed to connect Gmail. Please try again.');
    } finally {
      localStorage.removeItem('gmail_connect_success');
      localStorage.removeItem('gmail_connect_error');
    }
  };

  useEffect(() => {
    const handleStorageChange = (e: StorageEvent) => {
      if (
        e.key === 'gmail_connect_success' ||
        e.key === 'gmail_connect_error'
      ) {
        handleGmailConnectResult(e.key, e.newValue);
      }
    };

    window.addEventListener('storage', handleStorageChange);

    const successData = localStorage.getItem('gmail_connect_success');
    if (successData) {
      handleGmailConnectResult('gmail_connect_success', successData);
    } else {
      const errorData = localStorage.getItem('gmail_connect_error');
      if (errorData) {
        handleGmailConnectResult('gmail_connect_error', errorData);
      }
    }

    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  const handleGmailConnect = (e: React.MouseEvent) => {
    e.preventDefault();
    clearMessages();
    localStorage.removeItem('gmail_connect_success');
    localStorage.removeItem('gmail_connect_error');
    handleGoogleGmailConnect();
  };

  console.log(user);

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto">
      <h1 className="text-xl sm:text-2xl font-[500] mb-6">My info</h1>
      <form
        onSubmit={handleSubmit}
        className="space-y-4 w-full md:w-3/4 lg:w-2/3 xl:w-3/5 mx-auto"
      >
        {user?.email?.endsWith('@gmail.com') && (
          <GmailConnectButton
            email={user.email}
            isConnected={!!user.emailPermissionGranted}
            onConnect={handleGmailConnect}
          />
        )}

        {formFields.map((field) => (
          <div
            key={field.key}
            className="flex flex-col sm:flex-row sm:items-center border-b border-gray-200 pb-4"
          >
            <span className="w-full sm:w-1/4 text-sm mb-2 sm:mb-0">
              {field.label}
            </span>
            <FormField
              field={field}
              value={formState[field.key]}
              onChange={handleInputChange}
            />
          </div>
        ))}

        <div className="flex flex-col mt-6 h-full pt-4">
          <div className="flex-grow">
            {submitMessage && (
              <p className="mb-4 text-green-600 text-right">{submitMessage}</p>
            )}
            {submitError && (
              <p className="mb-4 text-red-600 text-right whitespace-pre-line">
                {submitError}
              </p>
            )}
          </div>
          <div className="flex justify-end">
            <button
              type="submit"
              className="px-4 py-2 bg-gray-300 rounded-md hover:bg-gray-400 lg:w-1/4"
            >
              Save Changes
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MyInfo;
