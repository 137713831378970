import React from 'react';

const ContactUs: React.FC = () => {
  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto mr-20">
      <h1 className="text-2xl sm:text-3xl font-normal text-gray-600 mb-8">
        Contact us - We'd love to hear from you!
      </h1>

      <form className="space-y-4 py-6 pl-16">
        {/* <div>
          <input
            type="text"
            placeholder="Name"
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
          />
        </div>

        <div>
          <input
            type="email"
            placeholder="Email"
            className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200"
          />
        </div> */}

        <div>
          <textarea
            placeholder="Message"
            rows={6}
            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-200 resize-none"
          ></textarea>
        </div>

        {/* Add a submit button if needed */}
        <div className="flex justify-end">
          <button
            type="submit"
            className="px-4 py-2 bg-gray-400 text-white rounded-md hover:bg-gray-600 "
            onClick={(e) => e.preventDefault()}
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactUs;
