import { gql } from '@apollo/client';

export const GET_SHARED_PRODUCTS = gql`
  query sharedCollectionsWithMe {
    sharedCollectionsWithMe(type: "PRODUCTS") {
      purchasedProducts {
        id
        createdAt
        privacyStatus
        isStarred
        vendor
        orderReference
        product {
          name
          description
          currency
          image
          url
          price
          color
          size
        }
        user {
          firstName
          lastName
        }
      }
    }
  }
`;
