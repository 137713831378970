import { gql } from '@apollo/client';

export const UPDATE_PURCHASED_PRODUCT_NOTE = gql`
  mutation updatePurchasedProductNote(
    $id: ID!
    $input: PurchasedProductInputNote!
  ) {
    updatePurchasedProductNote(id: $id, input: $input) {
      success
      errors
    }
  }
`;
