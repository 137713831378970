import { gql } from '@apollo/client';

export const CREATE_SHARED_COLLECTION = gql`
  mutation createSharedCollection($collectionId: ID!, $userId: ID!) {
    createSharedCollection(
      input: { collection: $collectionId, secondary_user: $userId }
    ) {
      success
      errors
      sharedCollection {
        collection {
          isShared
          type
          name
          id
          privacyStatus
          parent {
            id
          }
          children {
            id
            pk
            name
            isShared
            name
            id
            type
            isPermanent
            parent {
              id
            }
            children {
              id
              pk
              name
              isShared
              name
              id
              type
              isPermanent
              parent {
                id
              }
              children {
                id
                pk
                name
                isShared
                name
                id
                type
                isPermanent
                parent {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;
