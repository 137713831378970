import { gql } from '@apollo/client';

export const DELETE_SHARED_COLLECTION = gql`
  mutation deleteSharedCollection(
    $collection: ID!
    $secondaryUser: ID!
    $user: ID!
  ) {
    deleteSharedCollection(
      collection: $collection
      secondaryUser: $secondaryUser
      user: $user
    ) {
      ok
      success
      errors
    }
  }
`;
