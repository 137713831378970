interface Collection {
  privacyStatus: string;
  id: string;
  type: string;
  children?: Collection[];
  name: string;
  pk: number;
  isShared: boolean;
  isPermanent: boolean;
  parent?: Collection;
}

interface StackItem {
  collection: Collection;
  parents: Collection[];
}

export const findCollectionAndParentsById = (
  collections: Collection[],
  id: any
): { collection: Collection | null; parents: Collection[] } => {
  const stack: StackItem[] = [];

  for (const collection of collections) {
    stack.push({ collection, parents: [] });

    while (stack.length > 0) {
      const { collection: currentCollection, parents } = stack.pop()!;

      if (currentCollection.id === id) {
        // Filter out parents with type 'CABINET'
        const filteredParents = parents.filter(
          (parent) => parent.type !== 'CABINET'
        );
        return { collection: currentCollection, parents: filteredParents };
      }

      if (currentCollection.children) {
        for (const child of currentCollection.children) {
          stack.push({
            collection: child,
            parents: [...parents, currentCollection],
          });
        }
      }
    }
  }

  return { collection: null, parents: [] };
};
