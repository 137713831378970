import {
  useState,
  useRef,
  useEffect,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import ReactDOM from 'react-dom';

interface RenameModalProps {
  currentName: string;
  onClose: () => void;
  onRename: (newName: string) => void;
}

const RenameModal: ForwardRefRenderFunction<
  HTMLDivElement,
  RenameModalProps
> = ({ currentName, onClose, onRename }, _ref) => {
  const [newName, setNewName] = useState(currentName);
  const modalRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleRenameClick = () => {
    if (newName && newName !== currentName) {
      onRename(newName);
    }
    onClose();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    inputRef.current?.focus();
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-[#D7DBCE] p-6 rounded-lg shadow-xl text-center max-w-sm w-full opacity-95"
      >
        <h2 className="mb-4 text-lg font-medium text-black">Rename list</h2>
        <div className="mb-6 bg-white bg-opacity-70 p-2 rounded-md">
          <input
            ref={inputRef}
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className="w-full bg-transparent text-black text-lg focus:outline-none"
            placeholder="Enter new name"
          />
        </div>
        <div className="flex justify-center space-x-8">
          {' '}
          <button
            onClick={handleRenameClick}
            className="w-12 h-12 bg-white text-black rounded-full text-xl font-bold hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-150 ease-in-out"
          >
            Y
          </button>
          <button
            onClick={onClose}
            className="w-12 h-12 bg-white text-black rounded-full text-xl font-bold hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-150 ease-in-out"
          >
            N
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default forwardRef(RenameModal);
