import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  getSharedCabinetsThunk,
  getSharedCollectionsThunk,
  getSharedProductsThunk,
} from './sharedWithMeThunk';

interface sharedWithMeState {
  collections: any[];
  cabinets: any[];
  products: any[];
  loading: boolean;
  error: string | null;
}

const initialState: sharedWithMeState = {
  collections: [],
  cabinets: [],
  products: [],
  error: null,
  loading: false,
};

const addAsyncCases = (
  builder: ActionReducerMapBuilder<sharedWithMeState>,
  thunk: any,
  fulfilledReducer: (state: sharedWithMeState, action: any) => void
) => {
  builder
    .addCase(thunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(thunk.fulfilled, (state, action) => {
      fulfilledReducer(state, action);
      state.loading = false;
    })
    .addCase(thunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
};

const sharedWithMeReducer = createReducer(initialState, (builder) => {
  addAsyncCases(builder, getSharedCabinetsThunk, (state, action) => {
    state.cabinets = action.payload;
  });

  addAsyncCases(builder, getSharedCollectionsThunk, (state, action) => {
    state.collections = action.payload;
  });

  addAsyncCases(builder, getSharedProductsThunk, (state, action) => {
    state.products = action.payload;
  });
});

export default sharedWithMeReducer;
