// export const updateNestedCollection = (
//   collections: any[],
//   updatedCollection: any
// ): any[] => {
//   return collections.map((collection) => {
//     if (collection.id === updatedCollection.id) {
//       return updatedCollection;
//     }
//     if (collection.children) {
//       return {
//         ...collection,
//         children: {
//           ...collection.children,
//           edges: updateNestedCollection(
//             collection.children.edges.map((edge: { node: any }) => edge.node),
//             updatedCollection
//           ).map((node) => ({ node })),
//         },
//       };
//     }
//     return collection;
//   });
// };

// export const removeCollection = (collections: any[], collectionId: string) => {
//   return collections.filter((collection) => {
//     if (collection.id === collectionId) {
//       return false;
//     }
//     if (collection.children && collection.children.edges) {
//       collection.children.edges = removeCollection(
//         collection.children.edges.map((edge: { node: any }) => edge.node),
//         collectionId
//       ).map((node) => ({ node }));
//     }
//     return true;
//   });
// };

export const updateNestedCollection = (
  collections: any[],
  updatedCollection: any
): any[] => {
  return collections.map((collection) => {
    if (collection.id === updatedCollection.id) {
      return updatedCollection;
    }
    if (collection.children && collection.children.length > 0) {
      return {
        ...collection,
        children: updateNestedCollection(
          collection.children,
          updatedCollection
        ),
      };
    }
    return collection;
  });
};

export const removeCollection = (
  collections: any[],
  collectionId: string
): any[] => {
  return collections.filter((collection) => {
    if (collection.id === collectionId) {
      return false;
    }
    if (collection.children && collection.children.length > 0) {
      collection.children = removeCollection(collection.children, collectionId);
    }
    return true;
  });
};

// export const moveCollectionToNewParent = (
//   collections: any[],
//   collectionId: string,
//   newParentId: string
// ): any[] => {
//   let collectionToMove: any = null;

//   // Helper function to remove the collection and return the updated collections
//   const removeCollection = (
//     collections: any[],
//     collectionId: string
//   ): any[] => {
//     return collections
//       .map((collection) => {
//         if (collection.children) {
//           return {
//             ...collection,
//             children: removeCollection(collection.children, collectionId),
//           };
//         }
//         if (collection.id === collectionId) {
//           collectionToMove = collection;
//           return null;
//         }
//         return collection;
//       })
//       .filter((collection) => collection !== null);
//   };

//   // Helper function to add the collection to the new parent
//   const addCollectionToParent = (
//     collections: any[],
//     collectionToMove: any,
//     parentId: string
//   ): any[] => {
//     return collections.map((collection) => {
//       if (collection.id === parentId) {
//         return {
//           ...collection,
//           children: collection.children
//             ? [...collection.children, collectionToMove]
//             : [collectionToMove],
//         };
//       }
//       if (collection.children) {
//         return {
//           ...collection,
//           children: addCollectionToParent(
//             collection.children,
//             collectionToMove,
//             parentId
//           ),
//         };
//       }
//       return collection;
//     });
//   };

//   // Remove the collection from its current parent
//   const updatedCollections = removeCollection(collections, collectionId);

//   // Add the collection to the new parent
//   return addCollectionToParent(
//     updatedCollections,
//     collectionToMove,
//     newParentId
//   );
// };

export const moveCollectionToNewParent = (
  collections: any[],
  collectionId: string,
  newParentId: string
): any[] => {
  let collectionToMove: any = null;

  // Helper function to remove the collection and return the updated collections
  const removeCollection = (collections: any[]): any[] => {
    return collections.reduce((acc: any[], collection) => {
      if (collection.id === collectionId) {
        collectionToMove = collection;
        return acc;
      }
      if (collection.children) {
        collection.children = removeCollection(collection.children);
      }
      acc.push(collection);
      return acc;
    }, []);
  };

  // Helper function to add the collection to the new parent
  const addCollectionToParent = (collections: any[]): any[] => {
    return collections.map((collection) => {
      if (collection.id === newParentId) {
        return {
          ...collection,
          children: collection.children
            ? [...collection.children, collectionToMove]
            : [collectionToMove],
        };
      }
      if (collection.children) {
        return {
          ...collection,
          children: addCollectionToParent(collection.children),
        };
      }
      return collection;
    });
  };

  // Remove the collection from its current parent
  const updatedCollections = removeCollection(collections);

  // Check if the collection to move was found
  if (!collectionToMove) {
    throw new Error(`Collection with id ${collectionId} not found`);
  }

  // Add the collection to the new parent
  return addCollectionToParent(updatedCollections);
};

export const addCollectionToNestedParent = (
  collections: any[],
  newCollection: any,
  parentId: string
): any[] => {
  return collections.map((collection) => {
    if (collection.id === parentId) {
      return {
        ...collection,
        children: collection.children
          ? [...collection.children, newCollection]
          : [newCollection],
      };
    }
    if (collection.children) {
      return {
        ...collection,
        children: addCollectionToNestedParent(
          collection.children,
          newCollection,
          parentId
        ),
      };
    }
    return collection;
  });
};

export const duplicateCollection = (
  collections: any[],
  duplicatedCollection: any,
  originalCollection: any
): any[] => {
  const parentId = originalCollection.parent
    ? originalCollection.parent.id
    : null;

  const addDuplicatedCollection = (
    collections: any[],
    duplicatedCollection: any,
    parentId: string | null
  ): any[] => {
    if (parentId === null) {
      // If the original collection is at the root level, add the duplicated collection to the root level
      return [...collections, duplicatedCollection];
    }

    return collections.map((collection) => {
      if (collection.id === parentId) {
        return {
          ...collection,
          children: collection.children
            ? [...collection.children, duplicatedCollection]
            : [duplicatedCollection],
        };
      }

      if (collection.children && collection.children.length > 0) {
        return {
          ...collection,
          children: addDuplicatedCollection(
            collection.children,
            duplicatedCollection,
            parentId
          ),
        };
      }

      return collection;
    });
  };

  return addDuplicatedCollection(collections, duplicatedCollection, parentId);
};
