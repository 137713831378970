import {
  useEffect,
  useRef,
  useState,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import ReactDOM from 'react-dom';

interface AddSubCollectionModalProps {
  isVisible: boolean;
  onClose: () => void;
  onAddSubList: (name: string) => void;
}

const AddSubCollectionModal: ForwardRefRenderFunction<
  HTMLDivElement,
  AddSubCollectionModalProps
> = ({ isVisible, onClose, onAddSubList }, _ref) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [newName, setNewName] = useState('');

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        modalRef.current &&
        !modalRef.current.contains(event.target as Node)
      ) {
        onClose();
      }
    };

    if (isVisible) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
      setNewName(''); // Clear input when modal is closed
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isVisible, onClose]);

  if (!isVisible) {
    return null;
  }

  const handleAddClick = () => {
    if (newName) {
      onAddSubList(newName);
    }
    onClose();
  };

  return ReactDOM.createPortal(
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div
        ref={modalRef}
        className="bg-[#D7DBCE] p-6 rounded-lg shadow-xl text-center max-w-sm w-full opacity-95"
        role="dialog"
        aria-modal="true"
        aria-labelledby="addSubCollectionModalTitle"
        onClick={(e) => e.stopPropagation()}
      >
        <h2
          id="addSubCollectionModalTitle"
          className="text-xl mb-4 font-medium text-black"
        >
          New list
        </h2>
        <div className="mb-6 bg-white bg-opacity-70 p-2 rounded-md">
          <input
            type="text"
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            className="w-full bg-transparent text-black text-lg focus:outline-none"
            placeholder="Enter new name"
          />
        </div>
        <div className="flex justify-center space-x-8">
          <button
            onClick={handleAddClick}
            className="w-12 h-12 bg-white text-black rounded-full text-xl font-bold hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-150 ease-in-out"
          >
            Y
          </button>
          <button
            onClick={onClose}
            className="w-12 h-12 bg-white text-black rounded-full text-xl font-bold hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-400 transition duration-150 ease-in-out"
          >
            N
          </button>
        </div>
      </div>
    </div>,
    document.body
  );
};

export default forwardRef(AddSubCollectionModal);
