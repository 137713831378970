import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import client from '../../utils/api';
import { SHARED_WITH_USERS } from '../../graphql/queries/users/sharedWithUsers';
import { DELETE_SHARED_COLLECTION } from '../../graphql/mutations/collections/removeSharedUser';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface UsersListModalProps {
  isVisible: boolean;
  onClose: () => void;
  collection: any;
}

const UsersListModal: React.FC<UsersListModalProps> = ({
  isVisible,
  onClose,
  collection,
}) => {
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const modalRef = useRef<HTMLDivElement>(null);
  const [users, setUsers] = useState<
    { id: string; firstName: string; lastName: string; username: string }[]
  >([]);
  const [activeDropdown, setActiveDropdown] = useState<string | null>(null);

  const [fetchUsers, { loading, error }] = useLazyQuery(SHARED_WITH_USERS, {
    client,
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const fetchedUsers = data.sharedWithUsers.edges.map(
        (edge: any) => edge.node
      );
      setUsers(fetchedUsers);
    },
  });

  const [deleteSharedCollection] = useMutation(DELETE_SHARED_COLLECTION, {
    client,
    onCompleted: (data) => {
      if (!data.deleteSharedCollection.success) {
        console.error(
          'Error removing shared user:',
          data.deleteSharedCollection.errors
        );
      }
    },
    onError: (error) => {
      console.error('Error removing shared user:', error);
    },
  });

  useEffect(() => {
    if (isVisible) {
      fetchUsers({ variables: { id: collection.id } });
    }
  }, [isVisible, fetchUsers, collection.id]);

  useEffect(() => {
    if (!isVisible) {
      setUsers([]);
    }
  }, [isVisible]);

  const handleRemoveUser = async (userId: string) => {
    try {
      const { data } = await deleteSharedCollection({
        variables: {
          collection: collection.id,
          secondaryUser: userId,
          user: currentUser?.id,
        },
      });

      if (data?.deleteSharedCollection?.success) {
        setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      }
      setActiveDropdown(null);
    } catch (error) {
      console.error('Error removing user:', error);
    }
  };

  const toggleDropdown = (userId: string) => {
    setActiveDropdown(activeDropdown === userId ? null : userId);
  };

  if (!isVisible) {
    return null;
  }

  return ReactDOM.createPortal(
    <div
      className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        ref={modalRef}
        className="bg-[#D7DBCE] p-6 rounded-lg shadow-xl text-center max-w-md w-full opacity-95"
        role="dialog"
        aria-modal="true"
        aria-labelledby="usersListModalTitle"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex justify-between items-center mb-4">
          <h2 id="usersListModalTitle" className="text-xl text-black">
            You've shared "{collection.name}" with:
          </h2>
          <button
            onClick={onClose}
            className="focus:outline-none text-black"
            aria-label="Close modal"
          >
            &times;
          </button>
        </div>
        {loading && (
          <div className="flex items-center justify-center mt-4">
            <svg
              className="animate-spin h-5 w-5 text-black"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4z"
              ></path>
            </svg>
            <p className="ml-2 text-black">Loading...</p>
          </div>
        )}
        {error && <p className="mt-4 text-red-500">Error: {error.message}</p>}
        {users.length > 0 && (
          <ul className="mt-4">
            {users.map((user) => (
              <li
                key={user.id}
                className="p-2 flex justify-between items-center text-black"
              >
                <span>
                  {user.firstName} {user.lastName} ({user.username})
                </span>
                <div className="relative">
                  <button
                    onClick={() => toggleDropdown(user.id)}
                    className="focus:outline-none text-black"
                  >
                    ...
                  </button>
                  {activeDropdown === user.id && (
                    <div className="absolute right-0 mt-2 w-28 bg-white rounded-md shadow-lg z-10 flex text-left items-center text-sm text-gray-700 hover:bg-gray-100">
                      <img src="/assets/remove.svg" className="w-4 h-4 ml-2" />
                      <button
                        onClick={() => handleRemoveUser(user.id)}
                        className="block ml-2 py-2"
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              </li>
            ))}
          </ul>
        )}
        {!loading && !error && users.length === 0 && (
          <p className="mt-4 text-black">
            No one! <br />
            Go ahead and start sharing with others
          </p>
        )}
      </div>
    </div>,
    document.body
  );
};

export default UsersListModal;
