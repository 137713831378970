import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import ConfirmationModal from '../modals/ConfirmationModal';
import {
  moveProductBetweenCollectionsThunk,
  deleteProductThunk,
  starProductThunk,
} from '../../store/slices/products/productThunks';

interface ContextMenuProps {
  isOpen: boolean;
  onClose: () => void;
  product: any;
  currentCollectionId: string;
  isOwner: boolean;
}

const ProductDetailsContextMenu: React.FC<ContextMenuProps> = ({
  isOpen,
  onClose,
  product,
  currentCollectionId,
  isOwner,
}) => {
  const dispatch = useDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationHandler, setConfirmationHandler] = useState<() => void>(
    () => {}
  );
  const [isMoveToOpen, setIsMoveToOpen] = useState(false);

  const collections = useSelector(
    (state: RootState) =>
      state.collections.collections.find(
        (collection: any) => collection.type === 'CABINET'
      )?.children || []
  );

  const starredCollection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'STARRED'
    )
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
        setIsMoveToOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleMoveTo = () => {
    setIsMoveToOpen((prev) => !prev);
  };

  const handleSelectList = (
    targetCollection: any,
    action: 'move' | 'duplicate'
  ) => {
    setConfirmationMessage(
      `Are you sure you want to ${action} "${product.name}" to "${targetCollection.name}"?`
    );
    setConfirmationHandler(() =>
      action === 'move' ? () => handleConfirmMove(targetCollection) : ''
    );
    setShowConfirmationModal(true);
  };

  const handleConfirmMove = async (targetCollection: any) => {
    await dispatch(
      moveProductBetweenCollectionsThunk({
        productId: product.id,
        oldCollectionId: currentCollectionId,
        newCollectionId: targetCollection.id,
      }) as any
    );

    setShowConfirmationModal(false);
    onClose();
  };

  const handleStarred = () => {
    setConfirmationMessage(
      `Are you sure you want to star "${product.product.name}"?`
    );
    setConfirmationHandler(() => handleConfirmStarred);
    setShowConfirmationModal(true);
  };

  const handleUnStar = () => {
    setConfirmationMessage(
      `Are you sure you want to remove "${product.product.name}" from starred?`
    );
    setConfirmationHandler(() => handleUnstarItem);
    setShowConfirmationModal(true);
  };

  const handleUnstarItem = async () => {
    try {
      await dispatch(
        starProductThunk({
          productId: product.id,
          targetCollectionId: starredCollection.id,
          isStarred: false,
          soruceCollectionId: currentCollectionId,
        }) as any
      );
    } finally {
      setShowConfirmationModal(false);
    }
  };

  const handleConfirmStarred = async () => {
    await dispatch(
      starProductThunk({
        productId: product.id,
        targetCollectionId: starredCollection.id,
        isStarred: true,
        soruceCollectionId: currentCollectionId,
      }) as any
    );
    setShowConfirmationModal(false);
    onClose();
  };

  const handleDeleteItem = () => {
    setConfirmationMessage(
      `Are you sure you want to delete "${product.product.name}"?`
    );
    setConfirmationHandler(() => handleConfirmDelete);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    await dispatch(
      deleteProductThunk({
        productId: product.id,
        collectionId: currentCollectionId,
      }) as any
    );

    setShowConfirmationModal(false);
    onClose();
  };

  return (
    <div
      ref={menuRef}
      className={`absolute z-10 bottom-0 left-4 w-48 bg-white rounded-md shadow-lg ${isOpen ? 'block' : 'hidden'}`}
    >
      <ul className="py-1">
        {isOwner && (
          <li
            onClick={handleMoveTo}
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
          >
            <img
              src="/assets/product-context-menu/addToList.svg"
              className="w-4 h-4 mr-2"
              alt="Move to"
            />
            Add to...
          </li>
        )}
        {isMoveToOpen &&
          collections.map((list: any) => (
            <li
              key={list.id}
              onClick={() => handleSelectList(list, 'move')}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
            >
              <img
                src={`/assets/archive-context-menu/folder.svg`}
                className="w-4 h-4 mx-3"
                alt={list.name}
              />
              {list.name}
            </li>
          ))}

        {isMoveToOpen && (
          <li
            onClick={() =>
              handleSelectList(
                { id: 'new', name: 'New list' },
                isMoveToOpen ? 'move' : 'duplicate'
              )
            }
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
          >
            {/* <img
              src="/assets/archive-context-menu/new-list.svg"
              className="w-4 h-4 mr-2"
              alt="New list"
            />
            New list */}
          </li>
        )}
        {product.isStarred ? (
          <li
            onClick={handleUnStar}
            className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
          >
            <img
              src="/assets/product-context-menu/star.svg"
              className="w-5 h-5 mr-2"
              alt="starred"
            />
            Unstar Item
          </li>
        ) : (
          <li
            onClick={handleStarred}
            className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
          >
            <img
              src="/assets/product-context-menu/star.svg"
              className="w-5 h-5 mr-2"
              alt="starred"
            />
            Starred Item
          </li>
        )}
        {isOwner && (
          <li
            onClick={handleDeleteItem}
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
          >
            <img
              src="/assets/archive-context-menu/delete.svg"
              className="w-4 h-4 mr-2"
              alt="Delete item"
            />
            Delete item
          </li>
        )}
      </ul>
      {showConfirmationModal && (
        <ConfirmationModal
          message={confirmationMessage}
          onConfirm={confirmationHandler}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default ProductDetailsContextMenu;
