import { createAction } from '@reduxjs/toolkit';

export const setLoading = createAction<boolean>('sharedWithMe/setLoading');
export const setError = createAction<string | null>('sharedWithMe/setError');

export const getSharedCabinets = createAction<any>(
  'sharedWithMe/getSharedCabinets'
);
export const getSharedCollections = createAction<any>(
  'sharedWithMe/getSharedCollections'
);

export const getSharedProducts = createAction<any>(
  'sharedWithMe/getSharedProducts'
);
