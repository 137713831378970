import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store'; // Adjust the path as needed
import { getSharedCabinetsThunk } from '../../store/slices/sharedWithMe/sharedWithMeThunk';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SCREEENS_URL } from '../../assets/constants';
import { useMutation } from '@apollo/client';
import { DELETE_SHARED_COLLECTION } from '../../graphql/mutations/collections/removeSharedUser'; // Adjust the path as needed
import client from '../../utils/api'; // Adjust the path as needed

const CabinetsSharedWithMe: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sharedWithMe = useSelector(
    (state: RootState) => state.sharedWithMe.cabinets
  );
  const loading = useSelector((state: RootState) => state.sharedWithMe.loading);
  const error = useSelector((state: RootState) => state.sharedWithMe.error);
  const currentUser = useSelector((state: RootState) => state.auth.user);
  const [selectedUser, setSelectedUser] = useState<{
    user: any;
    collections: any;
  } | null>(null);
  const [contextMenu, setContextMenu] = useState<{
    x: number;
    y: number;
    id: string;
  } | null>(null);

  const [deleteSharedCollection] = useMutation(DELETE_SHARED_COLLECTION, {
    client,
    onCompleted: (data) => {
      if (!data.deleteSharedCollection.success) {
        console.error(
          'Error removing shared Franklie:',
          data.deleteSharedCollection.errors
        );
      }
    },
    onError: (error) => {
      console.error('Error removing shared Franklie:', error);
    },
  });

  useEffect(() => {
    dispatch(getSharedCabinetsThunk() as any);
  }, [dispatch]);

  useEffect(() => {
    const handleClickOutside = () => setContextMenu(null);
    document.addEventListener('click', handleClickOutside);
    return () => document.removeEventListener('click', handleClickOutside);
  }, []);

  const getInitials = (firstName: string, lastName: string) => {
    return `${firstName[0]}${lastName[0]}`.toUpperCase();
  };

  const handleUserClick = (user: any) => {
    setSelectedUser(user);
  };

  const handleContextMenu = (e: React.MouseEvent, id: string) => {
    e.preventDefault();
    e.stopPropagation();
    setContextMenu({ x: e.clientX, y: e.clientY, id });
  };

  const handleDelete = async (id: string) => {
    try {
      const { data } = await deleteSharedCollection({
        variables: {
          collection: id,
          secondaryUser: currentUser?.id,
          user: sharedWithMe.find((entry) => entry.collections[0].id === id)
            ?.user.id,
        },
      });

      if (data?.deleteSharedCollection?.success) {
        dispatch(getSharedCabinetsThunk() as any);
        setSelectedUser(null);
      }
    } catch (error) {
      console.error('Error deleting shared Franklie:', error);
    }
    setContextMenu(null);
  };

  const handleItemClick = (type: string, id: string) => {
    if (type === 'user') {
      handleUserClick(sharedWithMe.find((entry) => entry.user.id === id));
    } else if (type === 'collection') {
      navigate(`/${SCREEENS_URL.SHARED_LIST}/${id}`);
    }
  };

  const renderContextMenu = () => {
    if (!contextMenu) return null;
    return (
      <div
        className="absolute bg-white border rounded shadow-md py-2 z-10"
        style={{ top: contextMenu.y, left: contextMenu.x }}
      >
        <button
          className="block w-full text-left px-4 py-2 hover:bg-gray-100"
          onClick={() => handleDelete(contextMenu.id)}
        >
          Delete
        </button>
      </div>
    );
  };

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto flex flex-col">
      {/* {loading && <p>Loading...</p>} */}
      {error && <p className="text-red-500">Error: {error}</p>}
      {!selectedUser ? (
        <>
          <h2 className="text-2xl mb-4">Shared with me {`>`} Franklies</h2>
          {sharedWithMe.length === 0 && !loading && (
            <div className="w-full">
              <p className="mb-4">No Franklies shared with you.</p>
              <p className="text-gray-600">
                Friends can share their Franklie with you when you need recs!
              </p>
              <p className="mt-4 text-gray-600">
                To share your Franklie, go to My Franklie and click{' '}
                <span className="mx-2 px-2 py-1 bg-gray-100 rounded">⋯</span>.
                Then type the name of the friend you want to share with (if they
                don't have a Franklie, they won't show up so tell them to sign
                up!).
              </p>
            </div>
          )}
          <ul className="w-full max-w-md">
            {sharedWithMe.map((entry) => (
              <li
                key={entry.user.id}
                className="flex items-center p-2 mb-2 rounded-md shadow-sm cursor-pointer border"
                onClick={() => handleItemClick('user', entry.user.id)}
              >
                <div className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full text-xl mr-4">
                  {getInitials(entry.user.firstName, entry.user.lastName)}
                </div>
                <div className="flex-grow">
                  <div className="font-medium">
                    {entry.user.firstName} {entry.user.lastName}'s Franklie
                  </div>
                  <div className="text-gray-600">{entry.user.email}</div>
                </div>
                <div className="ml-auto">
                  <button
                    className="focus:outline-none text-black"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleContextMenu(e, entry.collections[0].id);
                    }}
                  >
                    ...
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="w-full max-w-md">
          <h3 className="text-xl mb-4">
            {selectedUser.user.firstName}'s Franklie
          </h3>
          <ul>
            {selectedUser.collections[0].children.map((collection: any) => (
              <li
                key={collection.id}
                className="flex items-center p-2 mb-2 border rounded-md shadow-sm cursor-pointer w-5/6"
                onClick={() => handleItemClick('collection', collection.id)}
              >
                <img
                  src="/assets/base/folder.svg"
                  className="flex items-center justify-center w-7 h-7 text-xl mr-4"
                />
                <div className="flex-grow">
                  <div className="font-medium">{collection.name}</div>
                </div>
              </li>
            ))}
          </ul>
          <button
            onClick={() => setSelectedUser(null)}
            className="mt-4 text-gray-600 underline"
          >
            Back to users
          </button>
        </div>
      )}
      {renderContextMenu()}
    </div>
  );
};

export default CabinetsSharedWithMe;
