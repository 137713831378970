import React, { useEffect, useRef } from 'react';
import CabinetItem from './CabinetItem';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

interface CabinetItemsListProps {
  isAdding: boolean;
  newCollectionName: string;
  setNewCollectionName: (name: string) => void;
  handleSaveClick: () => void;
  handleCancelClick: () => void;
}

const CabinetItemsList: React.FC<CabinetItemsListProps> = ({
  isAdding,
  newCollectionName,
  setNewCollectionName,
  handleSaveClick,
  handleCancelClick,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const cabinet = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'CABINET'
    )
  );
  useEffect(() => {
    if (isAdding && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isAdding]);

  return (
    <div className="w-full sm:w-3/4 px-5 mx-auto">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-4">
        {cabinet?.children.map((item: any, index: number) => (
          <div key={index} className="flex justify-center items-center py-2">
            <CabinetItem item={item} />
          </div>
        ))}
        {isAdding && (
          <div className="relative flex items-center space-x-2 w-full bg-white transition-shadow duration-200 lg:px-4 lg:mx-4 ">
            <div className="flex items-center space-x-2 p-2 border rounded-lg shadow-sm w-full sm:w-11/12">
              <input
                ref={inputRef}
                type="text"
                value={newCollectionName}
                onChange={(e) => setNewCollectionName(e.target.value)}
                className="border rounded px-2 py-1 flex-1 w-full"
                placeholder="New List"
              />
              <button
                onClick={handleSaveClick}
                className="focus:outline-none border text-black px-2 py-1 rounded border-gray-400"
              >
                ✔
              </button>
              <button
                onClick={handleCancelClick}
                className="focus:outline-none text-black px-2 py-1 rounded border border-gray-400"
              >
                ✖
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CabinetItemsList;
