import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../store';
import { setAuthState } from '../../store/slices/auth';

const ConnectEmail: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    window.addEventListener('message', handlePostMessage);
    return () => {
      window.removeEventListener('message', handlePostMessage);
    };
  }, []);

  const handleGoogleSignIn = (granted: boolean) => {
    const googleAuthUrl = 'https://accounts.google.com/o/oauth2/v2/auth';
    const params = new URLSearchParams({
      client_id: import.meta.env.VITE_GOOGLE_CLIENT_ID,
      redirect_uri: `${window.location.origin}/auth/google/callback`,
      response_type: 'code',
      scope: granted
        ? 'email profile https://www.googleapis.com/auth/gmail.readonly'
        : 'email profile',
      access_type: 'offline',
      prompt: 'consent',
    });

    const isMobile =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    if (isMobile) {
      window.location.href = `${googleAuthUrl}?${params.toString()}`;
    } else {
      const width = 500;
      const height = 600;
      const left = (window.innerWidth - width) / 2;
      const top = (window.innerHeight - height) / 2;
      window.open(
        `${googleAuthUrl}?${params.toString()}`,
        'Google Sign-In',
        `width=${width},height=${height},left=${left},top=${top}`
      );
    }
  };

  const handlePostMessage = async (event: MessageEvent) => {
    if (event.origin !== `${window.location.origin}`) return;
    if (event.data.type === 'GOOGLE_SIGN_IN_SUCCESS') {
      const { payload } = dispatch(
        setAuthState({
          isAuthenticated: true,
          user: event.data.authData.user,
          token: event.data.authData.token,
        })
      );
      if (payload.token) {
        navigate('/');
      }
    }
  };

  return (
    <div className="flex flex-col bg-white  overflow-hidden">
      <div className="flex-shrink-0 p-4 sm:p-6 lg:p-8">
        <header className="mb-2 sm:mb-4">
          <Link to="/">
            <img
              className="h-10 sm:h-12 md:h-14 w-auto"
              src="/assets/411.svg"
              alt="411 logo"
            />
          </Link>
        </header>
        <div className="flex flex-wrap items-center justify-center pt-2 sm:space-x-4">
          <div className="bg-gray-200 px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Sign up
          </div>
          <span className="text-gray-500">→</span>
          <div className="bg-[#BFB9FE] font-semibold px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Connect email
          </div>
          <span className="text-gray-500">→</span>
          <div className="bg-gray-200 px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Create account
          </div>
        </div>
      </div>

      <h1 className="text-center text-[#91987B] text-lg sm:text-xl md:text-2xl lg:text-3xl mb-4 font-bold py-16">
        Want to sync your Gmail while we're at it?
      </h1>

      <div className="flex items-center justify-center">
        <p className="text-[#91987B] sm:text-base md:text-2xl text-center px-12 lg:w-2/3  pb-12">
          By syncing your email, we can automatically pull your receipts onto
          the Franklie platform. If you prefer not to sync, you can forward your
          email receipts to
          <strong>
            <a href="mailto:myreceipts@franklie.ai .">
              {` myreceipts@franklie.ai .`}
            </a>
          </strong>
        </p>
      </div>

      <div className="flex flex-col sm:flex-row items-center  justify-center gap-4 w-full p-4">
        <div
          onClick={() => handleGoogleSignIn(true)}
          className="cursor-pointer w-full flex items-center justify-center text-center h-16 mx-12 sm:w-2/12  border font-medium rounded-md text-white bg-[#91987B] hover:bg-gray-400"
        >
          <p className="">Yes, sync my Gmail too</p>
        </div>

        <div
          onClick={() => handleGoogleSignIn(false)}
          className="cursor-pointer w-full flex items-center justify-center text-center h-16 mx-12 sm:w-2/12 border font-medium rounded-md  bg-[#ffffff] hover:bg-gray-100"
        >
          <p className="text-[#91987B]">No, I'll forward my receipts myself</p>
        </div>
      </div>
    </div>
  );
};

export default ConnectEmail;
