import { ApolloClient } from '@apollo/client';
import {
  TOKEN_AUTH,
  VERIFY_TOKEN,
  REFRESH_TOKEN,
} from '../graphql/mutations/auth';
import { AuthUser } from '../types/authTypes';

export interface AuthState {
  user: AuthUser | null;
  isAuthenticated: boolean;
  token?: string;
}

class AuthService {
  private client: ApolloClient<Object>;

  constructor(apolloClient: ApolloClient<Object>) {
    this.client = apolloClient;
  }

  async login(email: string, password: string): Promise<AuthState> {
    const { data } = await this.client.mutate({
      mutation: TOKEN_AUTH,
      variables: { email, password },
    });

    const { user } = data.tokenAuth;
    return {
      user,
      isAuthenticated: true,
    };
  }

  async verifyToken(): Promise<AuthUser | null> {
    try {
      const { data } = await this.client.mutate({
        mutation: VERIFY_TOKEN,
      });
      return data.verifyToken.payload;
    } catch (error) {
      return null;
    }
  }

  async refreshToken(): Promise<AuthState | null> {
    try {
      const { data } = await this.client.mutate({
        mutation: REFRESH_TOKEN,
      });

      if (data.refreshToken.token) {
        return {
          user: data.refreshToken.payload,
          isAuthenticated: true,
          token: data.refreshToken.token,
        };
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async validateSession(): Promise<AuthState | null> {
    try {
      const user = await this.verifyToken();
      if (user) {
        return {
          user,
          isAuthenticated: true,
        };
      }

      // If token is invalid, try refreshing
      return this.refreshToken();
    } catch (error) {
      return null;
    }
  }
}

export default AuthService;
