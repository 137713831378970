import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState, AppDispatch } from '../../store';
import { fetchCollectionProducts } from '../../store/slices/products/productThunks';
import { findCollectionAndUserByIdShared } from '../../utils/findCollectionAndUserByIdShared';
import SharedProductItem from './SharedProductItem';
import LoadingSpinner from '../loading';

const SharedCollection: React.FC = () => {
  const { collectionId } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const sharedWithMe = useSelector((state: RootState) => state.sharedWithMe);

  const { collection, user } = findCollectionAndUserByIdShared(
    sharedWithMe,
    collectionId
  );

  const products = useSelector(
    (state: RootState) => state.products[collection?.id ?? '']
  );

  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      if (collection) {
        setIsLoading(true);
        await dispatch(fetchCollectionProducts(collection.id));
        setIsLoading(false);
      }
    };

    fetchProducts();
  }, [collection, dispatch]);

  const handleContextMenuOpen = () => {
    setContextMenuOpen(!contextMenuOpen);
  };

  if (!collection) {
    return <div>Collection not found</div>;
  }

  const breadcrumbTitle = [
    `${user.firstName} ${user.lastName}`,
    collection.name,
  ].join(' > ');

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto flex flex-col">
      <div className="flex items-center mb-4 sm:mb-6">
        <img
          src="/assets/base/folder.svg"
          alt="Collection logo"
          className="h-8 w-8 sm:h-10 sm:w-10 md:h-12 md:w-12 py-1"
        />
        <h2 className="text-xl sm:text-2xl md:text-3xl pl-2">
          {breadcrumbTitle}
        </h2>
        <div className="relative ml-2">
          <button className="text-gray-500" onClick={handleContextMenuOpen}>
            ...
          </button>
          {contextMenuOpen && (
            <div
              className="absolute top-4 left-2 shadow-lg bg-white rounded-md p-4 cursor-pointer"
              onClick={handleContextMenuOpen}
            >
              Delete
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-center px-4 sm:px-8 md:px-12 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-1 w-5/6 lg:grid-cols-2 gap-y-4 gap-x-8 sm:gap-x-10 lg:gap-x-16">
          {isLoading ? (
            <div>
              <LoadingSpinner />
            </div>
          ) : products && products.length > 0 ? (
            products.map((product) => (
              <SharedProductItem
                key={product.id}
                product={product}
                collectionId={collection.id}
              />
            ))
          ) : (
            <div className="col-span-full text-center">
              No products in shared list
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SharedCollection;
