// components/home/MainArea.tsx
import React from 'react';
import { useParams } from 'react-router-dom';
import Cabinet from '../cabinet-details-view';
import CabinetsSharedWithMe from '../shared-with-me/CabinetsSharedWithMe';
import CollectionsSharedWithMe from '../shared-with-me/CollectionsSharedWithMe';
import Archive from '../archive';
import { SCREEENS_URL } from '../../assets/constants';
import UnsortedMail from '../unsorted-mail';
import Collection from '../collection';
import SharedCollection from '../shared-with-me/SharedCollection';
import MyStarred from '../starred';
import ProductView from '../products';
import SharedProducts from '../shared-with-me/SharedProducts';
import Settings from '../settings';
import MyAccount from '../settings/myAccount';
import PrivacyAndSharing from '../settings/PrivacyAndSharing';
import TermsConditions from '../settings/TermsConditions';
import PrivacyPolicy from '../settings/PrivacyPolicy';
import ContactUs from '../settings/ContactUs';
import MyInfo from '../settings/myAccount/MyInfo';
import SharePreferences from '../settings/myAccount/SharePreferences';
import DeleteAccount from '../settings/myAccount/DeleteAccount';

interface RouteConfig {
  [key: string]: React.ComponentType;
}

const routeConfig: RouteConfig = {
  [SCREEENS_URL.CABINET]: Cabinet,
  [SCREEENS_URL.STARRED]: MyStarred,
  [SCREEENS_URL.SHAREDWITHME.CABINETS]: CabinetsSharedWithMe,
  [SCREEENS_URL.SHAREDWITHME.LISTS]: CollectionsSharedWithMe,
  [SCREEENS_URL.ARCHIVE]: Archive,
  [SCREEENS_URL.INBOX]: UnsortedMail,
  [SCREEENS_URL.CUSTOM]: Collection,
  [SCREEENS_URL.PRODUCT]: ProductView,
  [SCREEENS_URL.SHARED_LIST]: SharedCollection,
  [SCREEENS_URL.SHAREDWITHME.PRODUCTS]: SharedProducts,
  [SCREEENS_URL.SETTINGS.INDEX]: Settings,
  [SCREEENS_URL.SETTINGS.SHARING_PRIVACY]: PrivacyAndSharing,
  [SCREEENS_URL.SETTINGS.TERMS]: TermsConditions,
  [SCREEENS_URL.SETTINGS.PRIVACY_POLICY]: PrivacyPolicy,
  [SCREEENS_URL.SETTINGS.CONTACT_US]: ContactUs,
  [SCREEENS_URL.SETTINGS.MY_ACCOUNT.INDEX]: MyAccount,
  [SCREEENS_URL.SETTINGS.MY_ACCOUNT.MY_INFO]: MyInfo,
  [SCREEENS_URL.SETTINGS.MY_ACCOUNT.SHARED_PREF]: SharePreferences,
  [SCREEENS_URL.SETTINGS.MY_ACCOUNT.DELETE_ACCOUNT]: DeleteAccount,
};

const MainArea: React.FC = () => {
  const { collectionType } = useParams<{ collectionType: string }>();

  const Component =
    routeConfig[collectionType as keyof RouteConfig] ||
    (() => <p>not found...</p>);

  return (
    <main className="p-6 flex-1">
      <Component />
    </main>
  );
};

export default MainArea;
