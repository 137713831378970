import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CabinetItemsList from './CabinetItemsList';
import ShareModal from '../modals/ShareModal';
import { createCollection } from '../../store/slices/collections/collectionThunks';
import { RootState } from '../../store';

interface CabinetProps {}

const Cabinet: React.FC<CabinetProps> = () => {
  const { user } = useSelector((state: RootState) => state.auth);
  const cabinet = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'CABINET'
    )
  );
  const [isModalVisible, setModalVisible] = useState(false);
  const dispatch = useDispatch();
  const [newCollectionName, setNewCollectionName] = useState('');
  const [isAdding, setIsAdding] = useState(false);

  const handleShareClick = () => {
    setModalVisible(true);
  };

  const handleCloseModal = () => {
    setModalVisible(false);
  };

  const handleSaveClick = () => {
    if (newCollectionName.trim()) {
      dispatch(
        createCollection({ name: newCollectionName, parent: cabinet.id }) as any
      );
      setNewCollectionName('');
      setIsAdding(false);
    }
  };

  const handleAddClick = () => {
    setIsAdding(true);
  };

  const handleCancelClick = () => {
    setNewCollectionName('');
    setIsAdding(false);
  };

  const GmailBadge = () => {
    if (!user?.email?.endsWith('@gmail.com')) return null;

    if (user?.emailPermissionGranted) {
      return (
        <div className="flex items-center ml-2 bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
          <svg
            className="w-4 h-4 mr-1"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
            />
          </svg>
          Synced
        </div>
      );
    }

    return (
      <div className="flex items-center ml-2 bg-red-100 text-red-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
        <svg
          className="w-4 h-4 mr-1"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
          />
        </svg>
        Not Synced
      </div>
    );
  };

  return (
    <div className="flex flex-col">
      <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between sm:mr-16 sm:px-24 px-4">
        <div className="flex items-center justify-between sm:justify-start sm:space-x-8 py-4 sm:py-6 sm:mb-2 w-full sm:w-auto">
          <h2 className="text-2xl sm:text-xl lg:text-3xl truncate max-w-[60%] sm:max-w-none">
            {user?.username}'s Franklie
          </h2>

          <div className="flex items-center space-x-4">
            <div className="flex items-center">
              <button
                onClick={handleShareClick}
                className="focus:outline-none p-2"
              >
                <img
                  src="/assets/cabinetShareIcon.svg"
                  alt="share"
                  className="w-6 h-6"
                />
              </button>
              <GmailBadge />
            </div>
            <button
              onClick={handleAddClick}
              className="focus:outline-none p-2 sm:hidden"
            >
              <img
                src="/assets/cabinet-item-context-icons/icon.svg"
                alt="add"
                className="w-6 h-5"
              />
            </button>
          </div>
        </div>
        <button
          onClick={handleAddClick}
          className="hidden sm:block focus:outline-none sm:mr-16 sm:pr-2"
        >
          <img
            src="/assets/cabinet-item-context-icons/icon.svg"
            alt="add"
            className="w-6 h-5"
          />
        </button>
      </div>

      <div className="flex flex-col sm:justify-between sm:mr-16 sm:px-24 px-4 mb-8">
        <p className="text-sm text-gray-500">Welcome! So glad you're here.</p>
        <p className="text-sm text-gray-500 mt-4">
          If you chose to sync your Gmail, check your{' '}
          <img
            src="/assets/unsortedMail.svg"
            alt="inbox"
            className="w-4 h-4 mx-2 inline"
          />
          Inbox in ~15 min for your last 30 days of buys. If you didn't sync, no
          worries. Forward receipts to{' '}
          <a href="mailto:myreceipts@franklie.ai" className="text-black">
            myreceipts@franklie.ai
          </a>
          , and they'll show up in ~15 min.
        </p>
        <p className="text-sm text-gray-500 mt-4">
          In the meantime, create Lists using the{' '}
          <img
            src="/assets/cabinet-item-context-icons/icon.svg"
            alt="add"
            className="w-4 h-4 mx-2 inline"
          />
          by your name. You can then file purchases from your Inbox into them.
        </p>
      </div>
      {cabinet?.children && (
        <div className="flex justify-center sm:pr-12">
          <CabinetItemsList
            isAdding={isAdding}
            newCollectionName={newCollectionName}
            setNewCollectionName={setNewCollectionName}
            handleSaveClick={handleSaveClick}
            handleCancelClick={handleCancelClick}
          />
        </div>
      )}
      <ShareModal
        isVisible={isModalVisible}
        onClose={handleCloseModal}
        collection={cabinet}
      />
    </div>
  );
};

export default Cabinet;
