import { gql } from '@apollo/client';

export const LOGIN_MUTATION = gql`
  mutation TokenAuthWithEmail($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      errors
      token
      success
      refreshToken
      payload
      refreshExpiresIn
      user {
        firstName
        lastName
        id
        birthday
        zip
        email
        gender
        phone
      }
    }
  }
`;
