// graphql/queries/collections.ts
import { gql } from '@apollo/client';

export const GET_SHARED_WITH_ME = gql`
  query sharedCollectionsWithMe($type: String!) {
    sharedCollectionsWithMe(type: $type) {
      user {
        id
        username
        firstName
        lastName
      }
      collections {
        id
        pk
        name
        privacyStatus
        children {
          id
          pk
          name
          privacyStatus
          children {
            id
            pk
            name
            privacyStatus
            children {
              id
              pk
              name
              privacyStatus
            }
          }
        }
      }
    }
  }
`;
