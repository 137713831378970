import React from 'react';
import { Link } from 'react-router-dom';
import { SCREEENS_URL } from '../../assets/constants';

interface MenuItem {
  title: string;
  path: string;
  externalUrl?: string;
}

const MenuItem: React.FC<{ item: MenuItem }> = ({ item }) => {
  if (item.externalUrl) {
    return (
      <a
        href={item.externalUrl}
        target="_blank"
        rel="noopener noreferrer"
        className="w-full text-left px-4 py-2 border rounded flex justify-between items-center"
      >
        {item.title}
      </a>
    );
  }

  return (
    <Link
      to={`/${item.path}`}
      className="w-full text-left px-4 py-2 border rounded flex justify-between items-center"
    >
      {item.title}
    </Link>
  );
};

const Settings: React.FC = () => {
  const menuItems: MenuItem[] = [
    {
      title: 'My account',
      path: SCREEENS_URL.SETTINGS.MY_ACCOUNT.INDEX,
    },
    {
      title: 'Sharing & Privacy',
      path: SCREEENS_URL.SETTINGS.SHARING_PRIVACY,
    },
    {
      title: 'Terms & Conditions',
      path: SCREEENS_URL.SETTINGS.TERMS,
      externalUrl: 'https://franklie.ai/terms-of-service',
    },
    {
      title: 'Privacy Policy',
      path: SCREEENS_URL.SETTINGS.PRIVACY_POLICY,
      externalUrl: 'https://franklie.ai/privacy-policy',
    },
    // {
    //   title: 'Contact Us',
    //   path: SCREEENS_URL.SETTINGS.CONTACT_US,
    // },
  ];

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto">
      <h1 className="text-xl sm:text-2xl font-[500] mb-6">Settings</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-1">
          <nav className="space-y-2">
            {menuItems.map((item, index) => (
              <MenuItem key={index} item={item} />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Settings;
