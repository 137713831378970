import { gql } from '@apollo/client';

export const DUPLICATE_COLLECTION = gql`
  mutation duplicateCollection($id: ID!) {
    duplicateCollection(collectionId: $id) {
      collection {
        isShared
        type
        name
        id
        privacyStatus
        parent {
          id
        }
        children {
          id
          pk
          name
          isShared
          name
          id
          type
          isPermanent
          privacyStatus
          parent {
            id
          }
          children {
            id
            pk
            name
            isShared
            name
            id
            type
            isPermanent
            privacyStatus
            parent {
              id
            }
            children {
              id
              pk
              name
              isShared
              name
              id
              type
              isPermanent
              privacyStatus
              parent {
                id
              }
            }
          }
        }
      }
    }
  }
`;
