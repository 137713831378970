import React, { useState } from 'react';
import moment from 'moment';
import InboxProductContextMenu from './InboxProductContextMenu';
import { useNavigate } from 'react-router';

interface InboxProductItemProps {
  product: {
    id: string;
    privacyStatus: string;
    createdAt: string;
    isStarred: boolean;
    name: string;
    image: string;
    description: string;
    currency: string;
    url: string;
    price: string;
    brand: string;
  };
}

const InboxProductItem: React.FC<InboxProductItemProps> = ({ product }) => {
  const navigate = useNavigate();
  const formattedDate = moment(product.createdAt).format('MMM Do, YYYY');
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);

  const toggleContextMenu = (e: React.MouseEvent) => {
    e.stopPropagation();
    setIsContextMenuOpen((prev) => !prev);
  };

  const closeContextMenu = () => {
    setIsContextMenuOpen(false);
  };

  const handleItemClick = (e: React.MouseEvent) => {
    if (!isContextMenuOpen) {
      navigate(`/product/${product?.id}`);
    } else {
      e.stopPropagation();
    }
  };

  return (
    <div
      className="flex flex-col sm:flex-row border rounded-lg shadow-md p-4 m-2 w-11/12 sm:max-w-lg lg:max-w-xl relative cursor-pointer bg-white hover:shadow-lg transition-shadow duration-300"
      onClick={handleItemClick}
    >
      <img
        src={product.image || '/assets/411.svg'}
        alt={product.name}
        className="w-full h-52 sm:w-32 sm:h-40 mb-4 sm:mb-0 sm:mr-4 object-cover"
      />
      <div className="flex-1 flex flex-col">
        <div className="flex justify-between items-start mb-2">
          <h3 className="font-bold sm:text-lg mb-2">{product.name}</h3>
        </div>
        <p className="text-sm text-gray-600">
          {product.brand || 'Product Brand'}
        </p>
        <p className="text-gray-800 text-lg sm:text-base">
          ${product.price || 'Price'}
        </p>
        <p className="text-gray-500 text-xs">{formattedDate}</p>
        <button
          onClick={toggleContextMenu}
          className="absolute bottom-2 right-2 w-6 h-6 flex items-center justify-center text-gray-500 hover:bg-gray-200 rounded-full focus:outline-none transition-colors duration-200"
        >
          <span className="text-lg pb-2 ">&#8230;</span>
        </button>
        {isContextMenuOpen && (
          <InboxProductContextMenu
            isOpen={isContextMenuOpen}
            onClose={closeContextMenu}
            product={product}
          />
        )}
      </div>
    </div>
  );
};

export default InboxProductItem;
