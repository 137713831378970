import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import CabinetItemContextMenu from './CabinetItemContextMenu';
import { updateCollectionPrivacyStatusThunk } from '../../store/slices/collections';

interface CabinetItemProps {
  item: any;
}

const CabinetItem: React.FC<CabinetItemProps> = ({ item }) => {
  const collection = item;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [actionJustPerformed, setActionJustPerformed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleContextMenu = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsContextMenuOpen(true);
  };

  const closeContextMenu = () => {
    setIsContextMenuOpen(false);
  };

  const handleItemClick = () => {
    if (!actionJustPerformed && !isModalOpen) {
      navigate(`/lists/${item?.id}`);
    }
    setActionJustPerformed(false);
  };

  const handlePrivacyToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    const newPrivacyStatus =
      collection?.privacyStatus === 'PRIVATE' ? 'HIDDEN' : 'PRIVATE';
    dispatch(
      updateCollectionPrivacyStatusThunk({
        id: item.id,
        privacyStatus: newPrivacyStatus,
      }) as any
    ).then(() => {
      setActionJustPerformed(true);
    });
  };

  return (
    <div
      className="relative flex items-center space-x-2 w-full bg-white transition-shadow duration-200 lg:px-4 lg:mx-4"
      onContextMenu={handleContextMenu}
    >
      <button
        className="flex-shrink-0 flex items-center justify-center w-8 h-8 bg-white border border-gray-300 rounded-full focus:outline-none hover:bg-gray-100 transition-colors duration-200"
        onClick={handlePrivacyToggle}
        aria-label={
          collection?.privacyStatus === 'PRIVATE'
            ? 'Hide collection'
            : 'Unhide collection'
        }
      >
        {collection?.privacyStatus === 'PRIVATE' ? (
          <img
            src="/assets/cabinet-item-context-icons/sharedWith.svg"
            className="w-4 h-4 transition-shadow duration-200"
            alt="Shared"
          />
        ) : (
          <img src="/assets/locked.svg" className="w-4 h-4" alt="Locked" />
        )}
      </button>
      <div
        className="flex-grow flex items-center justify-between cursor-pointer min-w-0 border rounded-lg shadow-sm p-2"
        onClick={handleItemClick}
      >
        <div className="flex items-center space-x-2 min-w-0 flex-shrink">
          {collection?.icon && (
            <span className="flex-shrink-0">{collection.icon}</span>
          )}
          <span className="text-gray-800 truncate">{collection.name}</span>
        </div>
        <button
          className="flex-shrink-0 focus:outline-none p-1 hover:bg-gray-100 rounded-full transition-colors duration-200 ml-2"
          onClick={(e) => {
            e.stopPropagation();
            setIsContextMenuOpen(!isContextMenuOpen);
          }}
        >
          <svg
            className="w-6 h-6 text-gray-600"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 12H6.01M12 12H12.01M18 12H18.01"
            />
          </svg>
        </button>
      </div>
      {isContextMenuOpen && (
        <CabinetItemContextMenu
          item={collection}
          onClose={closeContextMenu}
          onActionPerformed={() => setActionJustPerformed(true)}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
};

export default CabinetItem;
