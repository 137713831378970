import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import ConfirmationModal from '../modals/ConfirmationModal';
import {
  moveProductBetweenCollectionsThunk,
  deleteProductThunk,
} from '../../store/slices/products/productThunks';

interface ArchiveProductItemContextMenuProps {
  isOpen: boolean;
  onClose: () => void;
  product: any;
}

const ArchiveProductItemContextMenu: React.FC<
  ArchiveProductItemContextMenuProps
> = ({ isOpen, onClose, product }) => {
  const dispatch = useDispatch();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationHandler, setConfirmationHandler] = useState<() => void>(
    () => {}
  );
  const menuRef = useRef<HTMLDivElement>(null);
  const [isAddToListOpen, setIsAddToListOpen] = useState(false);

  const collections = useSelector(
    (state: RootState) =>
      state.collections.collections.find(
        (collection: any) => collection.type === 'CABINET'
      )?.children || []
  );
  const archiveCollection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'ARCHIVE'
    )
  );

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        onClose();
        setIsAddToListOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  const handleMenuItemClick = (e: React.MouseEvent, action: () => void) => {
    e.preventDefault();
    e.stopPropagation();
    action();
  };

  const handleToggleAddToList = () => {
    setIsAddToListOpen((prev) => !prev);
  };

  const handleSelectList = (targetCollection: any) => {
    // setConfirmationMessage(
    //   `Are you sure you want to move "${product.name}" to "${targetCollection.name}"?`
    // );
    // setConfirmationHandler(() => () => handleConfirmMove(targetCollection));
    // setShowConfirmationModal(true);
    handleConfirmMove(targetCollection);
  };

  const handleConfirmMove = async (targetCollection: any) => {
    if (!archiveCollection) {
      console.error('Archive collection not found');
      return;
    }

    await dispatch(
      moveProductBetweenCollectionsThunk({
        productId: product.id,
        oldCollectionId: archiveCollection.id,
        newCollectionId: targetCollection.id,
      }) as any
    );

    setShowConfirmationModal(false);
    onClose();
  };

  const handleDeleteItem = () => {
    setConfirmationMessage(
      `Are you sure you want to delete "${product.name}" forever?`
    );
    setConfirmationHandler(() => handleConfirmDelete);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = async () => {
    if (!archiveCollection) {
      console.error('Archive collection not found');
      return;
    }

    await dispatch(
      deleteProductThunk({
        productId: product.id,
        collectionId: archiveCollection.id,
      }) as any
    );
    setShowConfirmationModal(false);
    onClose();
  };

  return (
    <div
      ref={menuRef}
      onMouseDown={(e) => e.stopPropagation()}
      className={`absolute z-10 top-full w-48 bg-white rounded-md shadow-lg ${isOpen ? 'block' : 'hidden'}`}
    >
      <ul className="py-1">
        <li
          onClick={(e) => handleMenuItemClick(e, handleToggleAddToList)}
          className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
        >
          <img
            src="/assets/archive-context-menu/addToList.svg"
            className="w-4 h-4 mr-2"
            alt="Add to list"
          />
          {isAddToListOpen ? 'Move to list' : 'Move to ...'}
        </li>
        {isAddToListOpen &&
          collections.map((list: any) => (
            <li
              key={list.id}
              onClick={(e) =>
                handleMenuItemClick(e, () => handleSelectList(list))
              }
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
            >
              <img
                src={`/assets/archive-context-menu/folder.svg`}
                className="w-4 h-4 mx-3"
                alt={list.name}
              />
              {list.name}
            </li>
          ))}
        {isAddToListOpen && (
          <li
            onClick={(e) =>
              handleMenuItemClick(e, () =>
                handleSelectList({ id: 'new', name: 'New list' })
              )
            }
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
          >
            {/* <img
              src="/assets/archive-context-menu/new-list.svg"
              className="w-4 h-4 mr-2"
              alt="New list"
            />
            New list */}
          </li>
        )}
        {!isAddToListOpen && (
          <li
            onClick={(e) => handleMenuItemClick(e, handleDeleteItem)}
            className="px-4 py-2 hover:bg-gray-100 cursor-pointer flex items-center"
          >
            <img
              src="/assets/archive-context-menu/delete.svg"
              className="w-4 h-4 mr-2"
              alt="Delete item"
            />
            Delete forever
          </li>
        )}
      </ul>
      {showConfirmationModal && (
        <ConfirmationModal
          message={confirmationMessage}
          onConfirm={confirmationHandler}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
    </div>
  );
};

export default ArchiveProductItemContextMenu;
