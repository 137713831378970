import { gql } from '@apollo/client';

export const MOVE_PURCHASED_PRODUCT_BETWEEN_COLLECTIONS = gql`
  mutation MovePurchasedProductBetweenCollections(
    $input: MovePurchasedProductBetweenCollectionsInput!
  ) {
    movePurchasedProductBetweenCollections(input: $input) {
      errors
      success
      collection {
        name
        id
        purchasedProducts {
          edges {
            node {
              id
              privacyStatus
              createdAt
              isStarred
              vendor
              orderReference
              product {
                id
                name
                image
                description
                currency
                url
                color
                size
              }
            }
          }
        }
      }
    }
  }
`;

export interface MovePurchasedProductBetweenCollectionsInput {
  oldCollectionId: string;
  newCollectionId: string;
  purchasedProductId: string;
}

export interface Product {
  id: string;
  privacyStatus: string;
  createdAt: string;
  isStarred: boolean;
  name: string;
  image: string;
  description: string;
  currency: string;
  url: string;
}
