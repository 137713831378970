import React from 'react';
import { Link } from 'react-router-dom';
import { SCREEENS_URL } from '../../../assets/constants';

interface MenuItem {
  title: string;
  path: string;
}

const MenuItem: React.FC<{ item: MenuItem }> = ({ item }) => (
  <Link
    to={`/${item.path}`}
    className={`w-full text-left px-4 py-2 border rounded flex justify-between items-center `}
  >
    {item.title}
  </Link>
);

const MyAccount: React.FC = () => {
  const menuItems: MenuItem[] = [
    {
      title: 'My info',
      path: SCREEENS_URL.SETTINGS.MY_ACCOUNT.MY_INFO,
    },
    // {
    //   title: 'Share preferences',
    //   path: SCREEENS_URL.SETTINGS.MY_ACCOUNT.SHARED_PREF,
    // },
    {
      title: 'Delete my account',
      path: SCREEENS_URL.SETTINGS.MY_ACCOUNT.DELETE_ACCOUNT,
    },
  ];

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto">
      <h1 className="text-xl sm:text-2xl font-[500] mb-6">My account</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        <div className="md:col-span-1">
          <nav className="space-y-2">
            {menuItems.map((item, index) => (
              <MenuItem key={index} item={item} />
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default MyAccount;
