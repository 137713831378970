import { gql } from '@apollo/client';

export const SEARCH_USERS = gql`
  query searchUsers($search: String!) {
    users(search: $search) {
      edges {
        node {
          id
          firstName
          lastName
          username
        }
      }
    }
  }
`;
