import { gql } from '@apollo/client';

export const UPDATE_PRODUCT_IS_STARRED = gql`
  mutation updatePurchasedProductIsStarred($id: ID!, $isStarred: Boolean!) {
    updatePurchasedProductIsStarred(id: $id, input: { isStarred: $isStarred }) {
      success
      errors
    }
  }
`;
