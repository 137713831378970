import { Link } from 'react-router-dom';

const ArchiveSection = () => {
  return (
    <Link to="/archive">
      <div className="relative flex items-center justify-between mb-2 cursor-pointer py-2 hover:bg-gray-200 rounded w-full text-left ml-2">
        <div className="flex items-center">
          <img src="/assets/base/archive.svg" className="w-6 h-6" />
          <span className="font-medium text-lg pl-2 ">Archive</span>
        </div>
      </div>
    </Link>
  );
};

export default ArchiveSection;
