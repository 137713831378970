import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RenameModal from '../modals/RenameModal';
import UsersListModal from '../modals/UsersListModal';
import {
  createCollection,
  deleteCollectionThunk,
  renameCollectionThunk,
  updateCollectionParentThunk,
  duplicateCollectionThunk,
  updateCollectionPrivacyStatusThunk,
} from '../../store/slices/collections';
import ConfirmationModal from '../modals/ConfirmationModal';
import { RootState } from '../../store';
import ShareModal from '../modals/ShareModal';
import AddSubListModal from '../modals/AddSubListModal';

interface CabinetItemContextMenuProps {
  item: any;
  onClose: () => void;
  onActionPerformed: () => void;
  setIsModalOpen: (isOpen: boolean) => void;
}

const CabinetItemContextMenu: React.FC<CabinetItemContextMenuProps> = ({
  item,
  onClose,
  onActionPerformed,
  setIsModalOpen,
}) => {
  const dispatch = useDispatch();
  const archiveCollection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection: any) => collection.type === 'ARCHIVE'
    )
  );

  const contextMenuRef = useRef<HTMLDivElement>(null);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showAddSubCollectionModal, setShowAddSubCollectionModal] =
    useState(false);
  const [showShareModal, setShowShareModal] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmationHandler, setConfirmationHandler] = useState<() => void>(
    () => {}
  );
  const [showUsersListModal, setShowUsersListModal] = useState(false);

  useEffect(() => {
    setIsModalOpen(
      showRenameModal ||
        showShareModal ||
        showConfirmationModal ||
        showUsersListModal ||
        showAddSubCollectionModal
    );
  }, [
    showRenameModal,
    showShareModal,
    showConfirmationModal,
    showUsersListModal,
    showAddSubCollectionModal,
    setIsModalOpen,
  ]);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      contextMenuRef.current &&
      !contextMenuRef.current.contains(event.target as Node)
    ) {
      if (
        !showRenameModal &&
        !showShareModal &&
        !showConfirmationModal &&
        !showUsersListModal &&
        !showAddSubCollectionModal
      ) {
        onClose();
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [
    showRenameModal,
    showShareModal,
    showConfirmationModal,
    showUsersListModal,
    showAddSubCollectionModal,
  ]);

  const handleRenameClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowRenameModal(true);
  };

  const handleRename = (newName: string) => {
    dispatch(renameCollectionThunk({ id: item.id, name: newName }) as any).then(
      () => {
        setShowRenameModal(false);
        onActionPerformed();
        onClose();
      }
    );
  };

  // const handleAddSubListClick = (e: React.MouseEvent) => {
  //   e.stopPropagation();
  //   setShowAddSubCollectionModal(true);
  // };

  const handleAddSubList = (newName: string) => {
    dispatch(createCollection({ name: newName, parent: item.id }) as any).then(
      () => {
        setShowAddSubCollectionModal(false);
        onActionPerformed();
        onClose();
      }
    );
  };

  const handleDuplicateClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    // setConfirmationMessage(
    //   `Are you sure you want to duplicate "${item.name}"?`
    // );
    // setConfirmationHandler(() => handleConfirmDuplicate);
    // setShowConfirmationModal(true);
    handleConfirmDuplicate();
  };

  const handleConfirmDuplicate = () => {
    dispatch(duplicateCollectionThunk(item.id) as any).then(() => {
      setShowConfirmationModal(false);
      onActionPerformed();
      onClose();
    });
  };

  const handleHideClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    // setConfirmationMessage(`Are you sure you want to hide "${item.name}"?`);
    // setConfirmationHandler(() => handleConfirmHide);
    // setShowConfirmationModal(true);
    handleConfirmHide();
  };

  const handleConfirmHide = () => {
    dispatch(
      updateCollectionPrivacyStatusThunk({
        id: item.id,
        privacyStatus: 'HIDDEN',
      }) as any
    ).then(() => {
      setShowConfirmationModal(false);
      onActionPerformed();
      onClose();
    });
  };

  const handleUnhideClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    // setConfirmationMessage(`Are you sure you want to unhide "${item.name}"?`);
    // setConfirmationHandler(() => handleConfirmUnhide);
    // setShowConfirmationModal(true);
    handleConfirmUnhide();
  };

  const handleConfirmUnhide = () => {
    dispatch(
      updateCollectionPrivacyStatusThunk({
        id: item.id,
        privacyStatus: 'PRIVATE',
      }) as any
    ).then(() => {
      setShowConfirmationModal(false);
      onActionPerformed();
      onClose();
    });
  };

  const handleShareClickItem = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowShareModal(true);
  };

  const handleSharedWithClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setShowUsersListModal(true);
  };

  const handleDeleteClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setConfirmationMessage(`Are you sure you want to delete "${item.name}"?`);
    setConfirmationHandler(() => handleConfirmDelete);
    setShowConfirmationModal(true);
  };

  const handleConfirmDelete = () => {
    dispatch(deleteCollectionThunk(item.id) as any).then(() => {
      setShowConfirmationModal(false);
      onActionPerformed();
      onClose();
    });
  };

  const handleArchiveClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setConfirmationMessage(`Are you sure you want to archive "${item.name}"?`);
    setConfirmationHandler(() => handleConfirmArchive);
    setShowConfirmationModal(true);
  };

  const handleConfirmArchive = () => {
    dispatch(
      updateCollectionParentThunk({
        id: item.id,
        parentId: archiveCollection.id,
      }) as any
    ).then(() => {
      setShowConfirmationModal(false);
      onActionPerformed();
      onClose();
    });
  };

  return (
    <>
      <div
        ref={contextMenuRef}
        className="absolute w-48 bg-white border rounded-md shadow-lg z-50 right-0 top-full mt-2
        "
        onClick={(e) => e.stopPropagation()}
      >
        <ul className="py-1">
          <li
            className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={handleRenameClick}
          >
            <img
              src="/assets/cabinet-item-context-icons/rename.svg"
              alt="icon"
              className="w-4 h-4"
            />
            <span>Rename</span>
          </li>
          {/* <li
            className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={handleAddSubListClick}
          >
            <img
              src="/assets/cabinet-item-context-icons/add-sublist.svg"
              alt="icon"
              className="w-4 h-4"
            />
            <span>Add sublist</span>
          </li> */}

          <li
            className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={handleDuplicateClick}
          >
            <img
              src="/assets/cabinet-item-context-icons/duplicate.svg"
              alt="icon"
              className="w-4 h-4"
            />
            <span>Duplicate list</span>
          </li>
          {item.privacyStatus === 'PRIVATE' ? (
            <li
              className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer"
              onClick={handleHideClick}
            >
              <img
                src="/assets/cabinet-item-context-icons/hideFolder.svg"
                alt="icon"
                className="w-4 h-4"
              />
              <span>Hide list</span>
            </li>
          ) : (
            <li
              className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer"
              onClick={handleUnhideClick}
            >
              <img
                src="/assets/cabinet-item-context-icons/sharedWith.svg"
                alt="icon"
                className="w-4 h-4"
              />
              <span>Unhide list</span>
            </li>
          )}
          <li
            className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={handleShareClickItem}
          >
            <img
              src="/assets/cabinet-item-context-icons/shareFolder.svg"
              alt="icon"
              className="w-4 h-4"
            />
            <span>Share list</span>
          </li>
          <li
            className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={handleSharedWithClick}
          >
            <img
              src="/assets/cabinet-item-context-icons/sharedWith.svg"
              alt="icon"
              className="w-4 h-4"
            />
            <span>Shared with...</span>
          </li>
          <li
            className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={handleDeleteClick}
          >
            <img
              src="/assets/cabinet-item-context-icons/delete.svg"
              alt="icon"
              className="w-4 h-4"
            />
            <span>Delete</span>
          </li>
          <li
            className="flex items-center space-x-2 p-2 hover:bg-gray-100 cursor-pointer"
            onClick={handleArchiveClick}
          >
            <img
              src="/assets/cabinet-item-context-icons/archive.svg"
              alt="icon"
              className="w-4 h-4"
            />
            <span>Archive</span>
          </li>
        </ul>
      </div>
      {showRenameModal && (
        <RenameModal
          currentName={item.name}
          onClose={() => setShowRenameModal(false)}
          onRename={handleRename}
        />
      )}
      {showConfirmationModal && (
        <ConfirmationModal
          message={confirmationMessage}
          onConfirm={confirmationHandler}
          onClose={() => setShowConfirmationModal(false)}
        />
      )}
      {showUsersListModal && (
        <UsersListModal
          isVisible={showUsersListModal}
          onClose={() => setShowUsersListModal(false)}
          collection={item}
        />
      )}
      {showShareModal && (
        <ShareModal
          isVisible={showShareModal}
          onClose={() => setShowShareModal(false)}
          collection={item}
        />
      )}
      {showAddSubCollectionModal && (
        <AddSubListModal
          onClose={() => setShowAddSubCollectionModal(false)}
          onAddSubList={handleAddSubList}
          isVisible={showAddSubCollectionModal}
        />
      )}
    </>
  );
};

export default CabinetItemContextMenu;
