// store/slice/collections/collectionsThunk.ts
import { createAsyncThunk } from '@reduxjs/toolkit';
import client from '../../../utils/api';
import {
  getSharedCabinets,
  getSharedCollections,
  getSharedProducts,
  setError,
  setLoading,
} from './sharedWithMeActions';
import { GET_SHARED_WITH_ME } from '../../../graphql/queries/collections/sharedWithMe/sharedWithMeUsers';
import { GET_SHARED_PRODUCTS } from '../../../graphql/queries/products/getSharedProducts';

export const getSharedCabinetsThunk = createAsyncThunk(
  'sharedWithMe/getSharedCabinets',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.query({
        query: GET_SHARED_WITH_ME,
        variables: { type: 'CABINETS' },
        fetchPolicy: 'network-only',
      });

      const users = response.data.sharedCollectionsWithMe.map(
        (user: any) => user
      );

      thunkAPI.dispatch(getSharedCabinets(users));
      thunkAPI.dispatch(setError(null));
      thunkAPI.dispatch(setLoading(false));

      return users;
    } catch (error: any) {
      thunkAPI.dispatch(setError(error.message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getSharedCollectionsThunk = createAsyncThunk(
  'sharedWithMe/getSharedCollections',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.query({
        query: GET_SHARED_WITH_ME,
        variables: { type: 'LISTS' },
      });

      const users = response.data.sharedCollectionsWithMe.map(
        (user: any) => user
      );

      thunkAPI.dispatch(getSharedCollections(users));
      thunkAPI.dispatch(setError(null));
      thunkAPI.dispatch(setLoading(false));

      return users;
    } catch (error: any) {
      thunkAPI.dispatch(setError(error.message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getSharedProductsThunk = createAsyncThunk(
  'sharedWithMe/getSharedProducts',
  async (_, thunkAPI) => {
    thunkAPI.dispatch(setLoading(true));
    try {
      const response = await client.query({
        query: GET_SHARED_PRODUCTS,
        fetchPolicy: 'network-only',
      });

      const products = response.data.sharedCollectionsWithMe.flatMap(
        (collection: any) => collection.purchasedProducts
      );

      thunkAPI.dispatch(getSharedProducts(products));
      thunkAPI.dispatch(setError(null));
      thunkAPI.dispatch(setLoading(false));

      return products;
    } catch (error: any) {
      thunkAPI.dispatch(setError(error.message));
      thunkAPI.dispatch(setLoading(false));
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
