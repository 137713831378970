// store/slice/collections/collectionsReducer.ts
import { createReducer, ActionReducerMapBuilder } from '@reduxjs/toolkit';
import {
  setCollections,
  setLoading,
  setError,
  updateCollection,
} from './collectionActions';
import {
  createCollection,
  deleteCollectionThunk,
  duplicateCollectionThunk,
  fetchCollections,
  renameCollectionThunk,
  updateCollectionParentThunk,
} from './collectionThunks';
import {
  addCollectionToNestedParent,
  duplicateCollection,
  moveCollectionToNewParent,
  removeCollection,
  updateNestedCollection,
} from './helpers';

interface CollectionsState {
  collections: any[];
  loading: boolean;
  error: string | null;
}

const initialState: CollectionsState = {
  collections: [],
  loading: false,
  error: null,
};

const addAsyncCases = (
  builder: ActionReducerMapBuilder<CollectionsState>,
  thunk: any,
  fulfilledReducer: (state: CollectionsState, action: any) => void
) => {
  builder
    .addCase(thunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(thunk.fulfilled, (state, action) => {
      fulfilledReducer(state, action);
      state.loading = false;
    })
    .addCase(thunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
};

const collectionsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setCollections, (state, action) => {
      state.collections = action.payload;
    })
    .addCase(setLoading, (state, action) => {
      state.loading = action.payload;
    })
    .addCase(setError, (state, action) => {
      state.error = action.payload;
    })
    .addCase(updateCollection, (state, action) => {
      state.collections = updateNestedCollection(
        state.collections,
        action.payload
      );
    });

  addAsyncCases(builder, fetchCollections, (state, action) => {
    state.collections = action.payload;
  });

  addAsyncCases(builder, createCollection, (state, action) => {
    const returnedCollection = action.payload;

    const parentId = returnedCollection.parent.id;
    state.collections = addCollectionToNestedParent(
      state.collections,
      returnedCollection,
      parentId
    );
  });

  addAsyncCases(builder, renameCollectionThunk, (state, action) => {
    state.collections = updateNestedCollection(
      state.collections,
      action.payload
    );
  });

  addAsyncCases(builder, deleteCollectionThunk, (state, action) => {
    state.collections = removeCollection(state.collections, action.payload);
  });

  addAsyncCases(builder, duplicateCollectionThunk, (state, action) => {
    const duplicatedCollection = action.payload;
    const parentId = duplicatedCollection.parent.id;

    state.collections = duplicateCollection(
      state.collections,
      duplicatedCollection,
      { parent: { id: parentId } }
    );
  });

  addAsyncCases(builder, updateCollectionParentThunk, (state, action) => {
    state.collections = moveCollectionToNewParent(
      state.collections,
      action.payload.id,
      action.payload.parent.id
    );
  });
});

export default collectionsReducer;
