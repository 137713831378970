import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router';

interface SidebarItemComponentProps {
  item: any;
}

const SidebarItemComponent: React.FC<SidebarItemComponentProps> = ({
  item,
}) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleItemClick = () => {
    if (item?.children && item?.children.length > 0) {
      setOpen(!open);
    }
    navigate(`/lists/${item?.id}`);
  };

  return (
    <div ref={ref}>
      <button
        onClick={handleItemClick}
        className="flex items-center p-2 hover:bg-gray-200 rounded w-full text-left"
      >
        <img
          src="/assets/base/folder.svg"
          className="w-4 h-4 flex-shrink-0 mr-2"
        />
        <span className="flex-grow whitespace-nowrap overflow-hidden text-ellipsis min-w-0">
          {item?.name}
        </span>
        {item?.children && item?.children?.length > 0 && (
          <svg
            className={`w-4 h-4 transform flex-shrink-0 ml-2 ${!open ? 'rotate-0' : 'rotate-180'}`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 9l-7 7-7-7"
            />
          </svg>
        )}
      </button>
      {open && item?.children && item?.children.length > 0 && (
        <div className="ml-6 mt-2 space-y-1">
          {item?.children?.map(
            (subItem: { id: React.Key | null | undefined }) => (
              <SidebarItemComponent key={subItem.id} item={subItem} />
            )
          )}
        </div>
      )}
    </div>
  );
};

export default SidebarItemComponent;
