import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AuthUser } from '../../../types/authTypes';

interface AuthState {
  user: AuthUser | null;
  isAuthenticated: boolean;
  token: string | null;
  loading: boolean;
  errors: string[] | null;
}

interface AuthSuccessPayload {
  token: string;
  user: AuthUser;
}

const initialState: AuthState = {
  user: null,
  isAuthenticated: false,
  token: null,
  loading: false,
  errors: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<Partial<AuthState>>) => {
      return { ...state, ...action.payload };
    },
    setUser: (state, action: PayloadAction<AuthUser>) => {
      state.user = action.payload;
    },
    clearAuth: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.token = null;
      state.errors = null;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setErrors: (state, action: PayloadAction<string[] | null>) => {
      state.errors = action.payload;
    },
    handleAuthSuccess: (state, action: PayloadAction<AuthSuccessPayload>) => {
      state.isAuthenticated = true;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.errors = null;
    },
    handleAuthFailure: (state, action: PayloadAction<string[]>) => {
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
      state.errors = action.payload;
    },
  },
});

export const {
  setAuthState,
  setUser,
  clearAuth,
  setLoading,
  setErrors,
  handleAuthSuccess,
  handleAuthFailure,
} = authSlice.actions;

export default authSlice.reducer;
