import SidebarItemComponent from './SidebarItem';
import { Link } from 'react-router-dom';
import { SCREEENS_URL } from '../../assets/constants';

const SharedWithMeSection = ({
  isSharedWithMeCollapsed,
  toggleSharedWithMeCollapse,
  shared,
}: {
  isSharedWithMeCollapsed: boolean;
  toggleSharedWithMeCollapse: () => void;
  shared: any;
}) => {
  const sharedCabinets = shared?.children.find(
    (collection: { type: string }) => collection.type === 'CABINETS'
  );

  const sharedCollections = shared?.children.find(
    (collection: { type: string }) => collection.type === 'LISTS'
  );

  const sharedProducts = shared?.children.find(
    (collection: { type: string }) => collection.type === 'PRODUCTS'
  );

  return (
    <div>
      <div className="relative flex items-center justify-between my-2 ml-2">
        <div
          className="flex items-center cursor-pointer"
          onClick={toggleSharedWithMeCollapse}
        >
          <div className="flex items-center">
            <img src="/assets/base/sharedWithMe.svg" className="w-6 h-6" />
            <span className="block font-medium pl-2 text-lg">
              Shared with me
            </span>
            <svg
              className={`w-4 h-4 ml-2.5 transform ${isSharedWithMeCollapsed ? 'rotate-0' : 'rotate-180'}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
      </div>
      {!isSharedWithMeCollapsed && shared && shared.children.length > 0 && (
        <>
          <nav className="mt-2 space-y-1 px-6">
            <Link to={`${SCREEENS_URL.SHAREDWITHME.CABINETS}`}>
              <SidebarItemComponent item={sharedCabinets} />
            </Link>
          </nav>
          <nav className="mt-2 space-y-1 px-6">
            <Link to={`${SCREEENS_URL.SHAREDWITHME.LISTS}`}>
              <SidebarItemComponent item={sharedCollections} />
            </Link>
          </nav>
          <nav className="mt-2 space-y-1 px-6">
            <Link to={`${SCREEENS_URL.SHAREDWITHME.PRODUCTS}`}>
              <SidebarItemComponent item={sharedProducts} />
            </Link>
          </nav>
        </>
      )}
    </div>
  );
};

export default SharedWithMeSection;
