import { gql } from '@apollo/client';

export const UPDATE_COLLECTION_PRIVACY_STATUS = gql`
  mutation updateCollectionPrivacyStatus(
    $id: ID!
    $privacyStatus: PrivacyStatus!
  ) {
    updateCollectionPrivacyStatus(
      id: $id
      input: { privacyStatus: $privacyStatus }
    ) {
      success
      errors
      collection {
        isShared
        type
        name
        id
        privacyStatus
        parent {
          id
        }
        children {
          id
          pk
          name
          isShared
          type
          isPermanent
          privacyStatus
          parent {
            id
          }
          children {
            id
            pk
            name
            isShared
            type
            isPermanent
            privacyStatus
            parent {
              id
            }
            children {
              id
              pk
              name
              isShared
              type
              isPermanent
              privacyStatus
              parent {
                id
              }
            }
          }
        }
      }
    }
  }
`;
