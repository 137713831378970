import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const NonGmailConnectGmail: React.FC = () => {
  const navigate = useNavigate();
  const handleGotIt = () => {
    navigate('/create-account');
  };

  return (
    <div className="flex flex-col bg-white  overflow-hidden">
      <div className="flex-shrink-0 p-4 sm:p-6 lg:p-8">
        <header className="mb-2 sm:mb-4">
          <Link to="/">
            <img
              className="h-10 sm:h-12 md:h-14 w-auto"
              src="/assets/411.svg"
              alt="411 logo"
            />
          </Link>
        </header>
        <div className="flex flex-wrap items-center justify-center pt-2 sm:space-x-4">
          <div className="bg-gray-200 px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Sign up
          </div>
          <span className="text-gray-500">→</span>
          <div className="bg-[#BFB9FE] font-semibold px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Connect email
          </div>
          <span className="text-gray-500">→</span>
          <div className="bg-gray-200 px-2 py-1 sm:px-4 sm:py-2 rounded text-[#2C2C2C] my-1">
            Create account
          </div>
        </div>
      </div>
      <div className="flex-grow flex flex-col items-center h-full justify-between w-full py-6 px-4">
        <h1 className="text-center text-[#91987B] text-lg sm:text-2xl md:text-3xl lg:text-4xl mb-12 font-normal">
          Forward your email receipts to <strong>myreceipts@franklie.ai</strong>
        </h1>
        <div className="flex flex-col sm:flex-col items-center justify-center sm:space-x-8 mb-8 mx-4 sm:mx-auto max-w-4xl">
          <p className="text-sm sm:text-base lg:text-xl text-center px-16 lg:px-20 text-[#0000008A]">
            Want us to auto-pull your receipts onto the Franklie platform? Go
            back and sign up with your Gmail account.
          </p>

          <p className="text-sm w-5/6 pt-12 sm:text-base lg:text-xl text-center sm:text-left text-[#0000008A] lg:max-w-none px-4 sm:px-0">
            Don’t use Gmail? Hang tight! We’ll be integrating additional email
            clients soon.
          </p>
        </div>
        <div className="flex flex-col items-center justify-center mb-6 w-full max-w-md mx-auto pt-6">
          <button
            onClick={handleGotIt}
            className="w-full sm:w-3/5 px-6 py-3 border border-transparent text-base  rounded-md font-black text-[#FFFFFF] bg-[#91987B] hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
          >
            GOT IT
          </button>
        </div>
      </div>
    </div>
  );
};

export default NonGmailConnectGmail;
