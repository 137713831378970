import React, { useState, useEffect, useRef, useCallback } from 'react';
import Navbar from '../../components/navbar';
import Sidebar from '../../components/sidebar/Sidebar';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from '../../store';
import LoginPage from '../auth/Login';
import { Outlet } from 'react-router';
import { fetchCollections } from '../../store/slices/collections';

const Dashboard: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const loading = useSelector((state: RootState) => state.collections.loading);
  const sidebarRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: RootState) => state.auth?.user);

  // Fetch collections only when user is available and not currently loading
  useEffect(() => {
    if (user && !loading) {
      dispatch(fetchCollections());
    }
  }, [dispatch, user]);

  // Close sidebar when clicking outside
  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        setSidebarOpen(false);
      }
    },
    [sidebarRef]
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [handleClickOutside]);

  // Lock body scroll when sidebar is open
  useEffect(() => {
    if (sidebarOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [sidebarOpen]);

  return (
    <div className="flex flex-col h-screen">
      {/* Show LoginPage if there is no user or an error occurs */}
      {!user ? (
        <LoginPage />
      ) : (
        <>
          {/* Sticky Navbar */}
          <div className="sticky top-0 z-10">
            <Navbar />
          </div>

          {/* Main layout with Sidebar and Outlet */}
          <div className="flex flex-1 overflow-hidden">
            {/* Sidebar toggle button for mobile */}
            <button
              onClick={() => setSidebarOpen(!sidebarOpen)}
              aria-expanded={sidebarOpen}
              aria-label={sidebarOpen ? 'Close sidebar' : 'Open sidebar'}
              className={`fixed left-0 z-20 rounded-r mt-4 bg-gray-200 hover:bg-gray-300 p-2 border-gray-300 md:hidden`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="currentColor"
                className={`h-3 w-3 text-gray-600 transform transition-transform ${sidebarOpen ? '-rotate-180' : ''}`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </button>

            {/* Sidebar */}
            <div
              ref={sidebarRef}
              className={`fixed md:static inset-y-0 left-0 bg-white transform ${
                sidebarOpen ? 'translate-x-0' : '-translate-x-full'
              } md:translate-x-0 transition-transform duration-300 ease-in-out z-30 lg:z-0 w-64 overflow-y-auto`}
            >
              <Sidebar />
            </div>

            {/* Main content, scrolling area */}
            <div className="flex-1 overflow-y-auto">
              <Outlet />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
