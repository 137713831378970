import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store'; // Adjust the path as needed
import React, { useEffect, useState } from 'react';
import { getSharedProductsThunk } from '../../store/slices/sharedWithMe/sharedWithMeThunk';
import LoadingSpinner from '../loading';
import SharedProductItem from './SharedProductItem';

const SharedProducts: React.FC = () => {
  const dispatch = useDispatch();
  const products = useSelector(
    (state: RootState) => state.sharedWithMe.products
  );
  const [isLoading, setIsLoading] = useState(true);
  const collection = useSelector((state: RootState) =>
    state.collections.collections.find(
      (collection) => collection.type === 'SHARED'
    )
  ).children.find((child: any) => child.type === 'PRODUCTS');
  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);

      await dispatch(getSharedProductsThunk() as any);
      setIsLoading(false);
    };
    fetchProducts();
  }, [dispatch]);

  const modifiedArray = products.map((item) => {
    const { product, ...rest } = item;
    return { ...rest, ...product };
  });

  return (
    <div className="px-4 sm:px-6 md:px-8 lg:px-16 xl:px-32 py-6 max-w-7xl mx-auto flex flex-col">
      <div className="items-center mb-4 sm:mb-6">
        <h2 className="text-2xl mb-4">Shared with me {`>`} Items</h2>
        {modifiedArray.length === 0 && (
          <div className="w-full">
            <p className="mb-4">No Items shared with you.</p>
            <p className="text-gray-600">
              Friends can share their Items with you when you need recs!
            </p>
            <p className="text-gray-600 mt-4">
              To share an Item, click into that item and choose to share. Then
              type the name of the friend you want to share with (if they don't
              have a Franklie, they won't show up so tell them to sign up!).
            </p>
          </div>
        )}
      </div>

      <div className="flex justify-center px-4 sm:px-8 md:px-12 w-full">
        <div className="grid grid-cols-1 sm:grid-cols-1 w-5/6 lg:grid-cols-2 gap-y-4 gap-x-8 sm:gap-x-10 lg:gap-x-16">
          {isLoading ? (
            <LoadingSpinner />
          ) : (
            modifiedArray &&
            modifiedArray.length > 0 &&
            modifiedArray.map((product) => (
              <SharedProductItem
                key={product.id}
                product={product}
                collectionId={collection.id}
              />
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SharedProducts;
